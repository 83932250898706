import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const FooterWrapper = styled.footer`
  ${media.lessThan('medium')`
        padding-top: 56px;
    `}
  ${media.greaterThan('medium')`
        padding-top: 42px;
    `}    
    background: #101010;
`;

const MenuWrapper = styled.div`
  ${media.lessThan('medium')`
        margin-top: 70px;
        text-align: center;
    `}
  ${media.greaterThan('medium')`
        text-align: right;
    `}
`;

const Copyright = styled.div`
  ${media.lessThan('medium')`
        margin-top: 68px;
        padding: 32px 0;
        font-size: 10px;
        line-height: 16px;        
    `}
  ${media.greaterThan('medium')`
        margin-top: 42px;
        padding: 24px 0;
        font-size: 12px;
        line-height: 20px;        
    `}
    border-top: 1px solid #555555;
  font-weight: 500;
  color: #646464;
`;

export { FooterWrapper, MenuWrapper, Copyright };

import styled from 'styled-components';

import { Toast } from '@bancobarigui/reactbook';

export const Text = styled.p`
  display: block;
  margin-right: 40px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #424548;
  @media only screen and (max-width: 1023px) {
    margin: 10px 0 20px 0;
  }
`;

export const Link = styled.a`
  color: #0090ff;
  margin-left: 4px;
  font-weight: bold;
  text-decoration: none;
`;

export const Icon = styled.div`
  margin-right: 20px;
`;

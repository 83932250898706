import { datadogRum } from "@datadog/browser-rum";

// Init at DataDog
export const DataDogServiceInit = () => {
  datadogRum.init({
    applicationId: "75387a41-276f-489a-94b9-c3b239616253",
    clientToken: "pub92bee1acd9b3d959fa129701b22c7077",
    env: process.env.REACT_APP_ARGV === "PROD" ? "production" : "development",
    sampleRate: 100,
    trackInteractions: false,
    service: "b2b-lp",
  });
};

// Create action at DataDog
export const DataDogServiceAction = (title, payload) => {
  datadogRum.addAction(title, payload);
};

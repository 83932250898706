import React from 'react';
import { Container, Row, Col } from '../../Grid';
import Image from '../Image';
import HeaderWrapper from './style';
import logoBari from '../../../static/images/logo.svg';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <Row noPaddingTop={true}>
          <Col sm={12} md={12} lg={12}>
            <Image src={logoBari} alt="Bari" />
          </Col>
        </Row>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

import React from 'react';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';
import FieldContainer from '../FieldContainer';
import { StyledInput, Hint } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const Input = props => {
  const { label, name, readOnly, error = null, hint = null, ...rest } = props;
  const hasHint = hint !== null;
  const hasError = error !== null;

  return (
    <FieldContainer error={error}>
      {label !== '' && (
        <Label
          htmlFor={name}
          readOnly={readOnly}
          hasHint={hasHint}
          hasError={hasError}
        >
          {label}
        </Label>
      )}
      {hasHint && <Hint readOnly={readOnly}>{hint}</Hint>}
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
      <StyledInput
        name={name}
        hasError={hasError}
        readOnly={readOnly}
        {...rest}
      />
    </FieldContainer>
  );
};

export default Input;

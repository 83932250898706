import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-11
 */
const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  ${props =>
    props.hasError &&
    `
    margin-bottom: 0px;
  `};
  ${props =>
    props.hasHint &&
    `
    margin-bottom: 0px;
  `};
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  ${props =>
    !props.readOnly &&
    `
        color: #101010;
    `};
  ${props =>
    props.readOnly &&
    `
        color: #8E8E8E;
    `};
`;

export default Label;

import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
function getWidthColumn(value) {
  return `width: ${(value / 12) * 100}%`;
}

const Col = styled.div`
  float: left;
  min-height: 1px;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    ${({ sm }) => sm && getWidthColumn(sm)};
  `}

  ${media.between('medium', 'large')`
    ${({ md }) => md && getWidthColumn(md)};
  `}

  ${media.greaterThan('large')`
    ${({ lg }) => lg && getWidthColumn(lg)};
  `}
`;

export default Col;

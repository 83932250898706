import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 68%;

  @media screen and (max-width: 1080px) {
    width: 82%;
  }

  p {
    color: #9ba5af;
    font-size: 14px;
    font-weight: 400;
    padding: 160px 0;
    line-height: 20px;
  }
`;

import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";

// CONTEXT
import UserContext from "../../context/UserContext";

// COMPONENTS
import Alert from "../../components/UI/Alert";
import withLayout from "../../components/UI/Layout";
import AboutSection from "./components/AboutSection";
import FormSection from "./components/FormSection";
import IntroSection from "./components/IntroSection";
import ModalExpired from "./components/ModalExpired";
import ModalFeedback from "./components/ModalFeedback";
import ModalInvalid from "./components/ModalInvalid";
import ModalSecurityCode from "./components/ModalSecurityCode";
import ModalWrong from "./components/ModalWrong";
import SecuritySection from "./components/SecuritySection";

const DigitalSignature = () => {
  const refSection = useRef(null);
  const {
    linkValid,
    linkExpired,
    alert: { active, message, labelBtn },
  } = useContext(UserContext);
  const [openModalValidation, setOpenModalValidation] = useState(false);
  const [openModalInvalid, setOpenModalInvalid] = useState(!linkValid);
  const [openModalWrong, setOpenModalWrong] = useState(false);
  const [openModalExpired, setOpenModalExpired] = useState(linkExpired);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);

  const executeScroll = useCallback(() => {
    refSection.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleResetStatus = useCallback(() => {
    setOpenModalValidation(false);
    setOpenModalInvalid(false);
    setOpenModalExpired(false);
    setOpenModalFeedback(false);
  }, []);

  const handleSubmitSuccess = useCallback((type) => {
    handleResetStatus();
    if (type === "success") {
      setOpenModalFeedback(true);
    } else {
      setOpenModalValidation(true);
    }
  }, []);

  const handleSubmitError = useCallback((type) => {
    handleResetStatus();
    if (type === "invalid") {
      setOpenModalInvalid(true);
    } else if (type === "wrong") {
      setOpenModalWrong(true);
    } else {
      setOpenModalExpired(true);
    }
  }, []);

  const handleBack = useCallback(() => {
    setOpenModalValidation(false);
    setOpenModalWrong(false);
  }, []);

  const renderModal = useMemo(() => {
    if (openModalValidation) {
      return (
        <ModalSecurityCode
          active={openModalValidation}
          onClose={handleBack}
          onSuccess={handleSubmitSuccess}
          onError={handleSubmitError}
        />
      );
    }
    if (openModalInvalid) {
      return <ModalInvalid active={openModalInvalid} />;
    }
    if (openModalExpired) {
      return <ModalExpired active={openModalExpired} />;
    }
    if (openModalFeedback) {
      return <ModalFeedback active={openModalFeedback} />;
    }
    if (openModalWrong) {
      return <ModalWrong active={openModalWrong} onClose={handleBack} />;
    }
    document.body.style.overflowY = "auto";
    return <></>;
  }, [
    openModalValidation,
    openModalInvalid,
    openModalExpired,
    openModalFeedback,
    openModalWrong,
  ]);

  return (
    <>
      <Helmet>
        <title>Assinatura Digital - Banco Bari</title>
      </Helmet>
      <IntroSection onClick={executeScroll} />
      <SecuritySection ref={refSection} />
      <FormSection
        onSuccess={handleSubmitSuccess}
        onError={handleSubmitError}
      />
      <AboutSection />
      {renderModal}
      <Alert
        className={active ? "Active" : ""}
        message={message}
        labelBtn={labelBtn}
      />
    </>
  );
};

export default withLayout({ header: true, footer: true })(DigitalSignature);

import React, { useCallback, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

//
import { Radio } from '@bancobarigui/reactbook';

// STYLES
import { Container, Error } from './styles';

const RadioList = ({ name, options, onChange }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback(e => {
    if (typeof onChange === 'function') {
      onChange(e.target.value);
    }
  }, []);

  return (
    <Container onChange={handleChange}>
      {options.map((option, index) => (
        <Radio
          checked={defaultValue === option.id}
          key={option.id}
          label={option.label}
          name={fieldName}
          onChange={handleChange}
          ref={elRef => (inputRefs.current[index] = elRef)}
          value={option.id}
        />       
      ))}
      {error && <Error title={error}>{error}</Error>}
    </Container>
  );
};

export default RadioList;

import * as Yup from 'yup';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as dayjs from 'dayjs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-27
 */
const formInitialValues = params => {
  const { ad = '', pn = '' } = params;

  const assingSendDate = dayjs().millisecond(ad).format('DD/MM/YYYY HH:mm');
  const phoneNumber = pn
    .split('')
    .map((v, i) => {
      let number = v;
      if (i === 1) {
        number += '9';
      }
      return number;
    })
    .join('');

  return {
    assingSendDate,
    proponentName: '',
    document: '',
    creditFinality: '',
    phoneNumber,
    acceptTerms: false,
    ad,
    pn,
  };
};

const formValidations = pt => {
  return Yup.object({
    proponentName: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Nome obrigatório. Tente novamente'),
    document:
      pt === 'PF'
        ? Yup.string()
            .test('Valida CPF', 'CPF inválido. Tente novamente.', e => {
              return cpf.isValid(e);
            })
            .required('CPF é obrigatório.')
        : Yup.string()
            .test('Valida CNPJ', 'CNPJ inválido. Tente novamente.', e => {
              return cnpj.isValid(e);
            })
            .required('CPF é obrigatório.'),
    creditFinality: Yup.string().required('Informe a finalidade do crédito.'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Você deve marcar campo de aceite de consulta ao SCR.',
    ),
  });
};

const removeMask = value => {
  return value.replace(/\D/g, '');
};

export { formValidations, formInitialValues, removeMask };

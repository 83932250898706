import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${props =>
    !props.containerType &&
    css`
      padding: 8px 0;
    `}
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Nunito', sans-serif;
  padding: 4px 0;
  font-size: 10px;
`;

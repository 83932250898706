import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const FieldWrapper = styled.div``;

const StyledInput = styled.input`
  ${media.lessThan('medium')`
    margin-bottom: 28px;
  `}
  ${media.greaterThan('medium')`
    margin-bottom: 32px;
  `}  
  ${props =>
    props.readOnly &&
    `
        background: #F6F6F6;
    `};
  ${props =>
    props.hasError &&
    `
        border-color: #F83D44;
    `};
`;

const Label = styled.label`
  display: block;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  ${props =>
    !props.readOnly &&
    `
        color: #101010;
    `};
  ${props =>
    props.readOnly &&
    `
        color: #8E8E8E;
    `};
  ${props =>
    !props.hasHint &&
    `
        margin-bottom: 6px;
    `};
`;

const Hint = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: 'Clan Web Pro Book', sans-serif;
  font-size: 12px;
  line-height: 22px;
  color: #8e8e8e;
`;

export { FieldWrapper, StyledInput, Label, Hint };

import styled from 'styled-components';

import checkIconWhite from '../../../../assets/check-icon-white.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  label {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    display: flex;
  }

  span {
    padding-left: 8px;
  }
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-size: 10px;
  font-family: 'Nunito', sans-serif;
  padding: 4px 0 !important;
`;

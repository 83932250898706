import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 92px;
  background: #323232 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 25px 16%;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    img {
      max-height: 30px;
    }

    div {
      margin-bottom: 3px;
    }
  }

  button {
    max-width: 227px;
    height: 44px;
    justify-content: center !important;
  }

  div {
    display: flex;
    align-items: center;
  }

  a {
    width: 163px;
    color: #fff;
    margin-right: 50px;
    cursor: pointer;
  }

  @media screen and (max-width: 1080px) {
    padding: 25px 9% !important;

    a {
      width: auto;
      margin-right: 0;
    }

    button {
      display: none !important;
    }
  }
`;

import styled from 'styled-components';
import media from 'styled-media-query';

// STATIC
import backgroundImage from '../../../static/images/bg-newsletter.png';
import backgroundImageSm from '../../../static/images/bg-newsletter-sm.png';

const AccessWrapper = styled.div`
  position: relative;
  background: #101010;
  border-radius: 10px;
  margin: 0 auto 164px;
  min-height: 590px;
  max-width: 1160px;
  padding: 74px 145px;
  overflow: hidden;

  ${media.lessThan('medium')`
    margin: 72px 8px;
    padding: 50px 18px;
    min-height: 100%;
    height: 70vh;
  `}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    background: url(${backgroundImageSm});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    ${media.greaterThan('medium')`
      max-width: 836px;
      background-attachment: fixed;    
      background: url(${backgroundImage});      
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    `};
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 29px auto 0;
  width: 218px;
`;

const IntroWrapper = styled.div`
  margin-top: 106px;
`;

const BackgroundImage = styled.div`
  position: relative;
  margin-top: 78px;

  ${media.lessThan('medium')`
    height: 264px;
  `}

  ${media.greaterThan('medium')`
    height: 480px;
  `}
  
  background: #101010;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: url(${backgroundImageSm});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    ${media.greaterThan('medium')`
      background-attachment: fixed;    
      background: url(${backgroundImage});      
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    `};
  }
`;

const AccessTitle = styled.div`
  display: flex;
  width: 90px;
  margin: 0 auto 31px;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 10px;
  line-height: 16px;
  color: #ffff;
  > img {
    margin-right: 8px;
  }
`;

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6f7;
  border-radius: 10px;
  margin: 50px auto 100px;
  min-height: 630px;
  max-width: 894px;
  padding: 50px 145px 50px 145px;

  ${media.lessThan('medium')`
    margin: 72px 8px;
    padding: 50px 18px;
    min-height: 100%;
    height: 100%;
  `}
`;

const WarningTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #d02931;
  padding: 0px 0px 34px 0px;
  > span {
    padding-left: 9px;
  }
`;

const WarningSubTitle = styled.div`
  display: flex;
  text-align: center;
  margin: 0 auto 31px;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: #101010;
  max-width: 550px;
`;

const WarningNavigator = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 40px 0px 10px 50px;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 16px;
  line-height: 32px;
  color: #424548;
  max-width: 550px;

  ${media.lessThan('medium')`
    margin: 10px 0px 10px 20px;
  `}
`;

const WarningTips = styled.div`
  margin: 0px 0px 0px 50px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  ${media.lessThan('medium')`
    margin: 0px 0px 0px 20px;
  `}
`;
const WarningTip = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > img {
    padding-left: 10px;
  }
  padding-top: 15px;

  ${media.lessThan('medium')`
    padding-left: 0px;
  `}
`;

const WarningTipNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101010;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 10px;
`;

const WarningTipText = styled.div`
  font-size: 14px;
  line-height: 25px;
  max-width: 398px;

  ${media.lessThan('medium')`
    max-width: 90%;
  `}
`;
const WarningTipTextBold = styled.b`
  font-size: 14px;
  line-height: 25px;
  padding: 0 2px 0 2px;
`;

export {
  AccessTitle,
  AccessWrapper,
  BackgroundImage,
  ButtonWrapper,
  IntroWrapper,
  WarningWrapper,
  WarningTitle,
  WarningSubTitle,
  WarningNavigator,
  WarningTips,
  WarningTip,
  WarningTipNumber,
  WarningTipText,
  WarningTipTextBold,
};

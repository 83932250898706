import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './context/UserContext'

// LIBS
import {
  getUrlParams,
  checkIfLinkIsValid,
  checkIfLinkIsExpired,
} from './libs/params'

// ROUTES
import AvailableRoutes from './routes'

// SERVICES
import { DataDogServiceInit } from './services/DataDogService'

// STYLES
import GlobalStyle from './styles/global'

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-NNRJNRXB'
}
 
TagManager.initialize(tagManagerArgs)

// Init at DataDog
DataDogServiceInit()


const App = () => {
  const parameters = getUrlParams()
  const UserProviderComponent = ({ children }) => {
    return (
      <UserProvider
        parameters={parameters}
        linkValid={checkIfLinkIsValid(parameters)}
        linkExpired={checkIfLinkIsExpired(parameters)}
       >
        {children}
      </UserProvider>
    )
  }

  return (
    <>
      <BrowserRouter>
        <AvailableRoutes userProvider={UserProviderComponent} />
      </BrowserRouter>
      <GlobalStyle />
    </>
  )
}

export default App

import React from 'react';

// COMPONENTS
import ButtonWrapper from '../../../components/UI/ButtonWrapper';
import Image from '../../../components/UI/Image';
import Title from '../../../components/Typography';

// ENUMS
import { sideButton } from '@bancobarigui/reactbook';

// STATIC
import eyeIcon from '../../../static/images/eye-icon.svg';

// STYLES
import {
  AccessTitle,
  AccessWrapper,
  ButtonWrapper as ButtonWrapperStyled,
} from './styles';

const AccessSection = () => {
  const handleGoToPage = () => {
    let url = 'https://portal.dev.parceirosbari.com.br/simulador';

    if (process.env.NODE_ENV === 'production') {
      url = 'https://portal.parceirosbari.com.br/simulador';
    }

    window.location = url;
  };

  return (
    <AccessWrapper>
      <AccessTitle>
        <Image src={eyeIcon} alt="Fique atento" />
        Fique atento
      </AccessTitle>
      <Title size={3} center={true} light={true}>
        Mais novidades ainda estão por vir para deixar o nosso Portal de
        Parceiros uma ferramenta ainda mais completa para você fechar bons
        negócios.
      </Title>
      <ButtonWrapperStyled>
        <ButtonWrapper
          fixedWidth
          blue
          sideIcon={sideButton.RIGHT}
          onClick={handleGoToPage}
        >
          Acesse o Portal
        </ButtonWrapper>              
      </ButtonWrapperStyled>
    </AccessWrapper>
  );
};

export default AccessSection;

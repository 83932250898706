import React, { useCallback, useRef, useState } from 'react'

import { cnpj, cpf } from 'cpf-cnpj-validator'

import { Form } from '@unform/web'
import qs from 'querystring'
import * as Yup from 'yup'

// ASSETS
import arrowRight from '../../../../assets/arrow-right-line.svg'
import checkIconWhite from '../../../../assets/check-icon-white.svg'
import thumbsCheck from '../../../../assets/thumbs-check.svg'

// COMPONENTS
import { Button } from '@bancobarigui/reactbook'
import RadioList from '../RadioList'
import SelectContainer from '../SelectContainer'

import CheckboxList from '../CheckboxList'
import Input from '../Input'
import MaskedInput from '../MaskedInput'

// STYLES
import {
  ButtonGroup,
  ButtonWrapper,
  ChoiceButtons,
  Container,
  Content,
  FlexDiv,
  FormStep,
  FormStepData,
  FormStepNumber,
  FormWrapper,
  MarginBottom24,
  Step,
  SucessStep,
  VerticalLine,
} from './styles'

// UTILS
import {
  actingFieldOptions,
  actingTimeOptions,
  actingTypeOptions,
  clientFontOptions,
  clientProfileOptions,
  howFindUsOptions,
  ufOptions,
  whyBeOurPartnerOptions,
} from '../../../../utils/formOptions'

const StepEnum = {
  FIRST: 'first',
  SECOND: 'second',
  FINISHED: 'finished',
}

const HashEnum = {
  RECORDTYPEID: '0121U000000NgmHQAS',
  LEADORIGIN: '00N1U00000UzEWd',
   ORIGINSIMULATOR:'00N1U00000VhBHJ',
  CPF: '00N1U00000TdCrF',
  HOWWORK: '00N1U00000UzjP8',
  WHATSCNPJ: '00N1U00000TdCrE',
  WEBSITE: '00N1U00000VDW7O',
  COMPANYNAME: '00N1U00000VDW6z',
  LINEBUSINESS: '00N1U00000VDW7A',
  OTHERLINEBUSINESS: '00N1U00000VDW7B',
  ACTINGSTATE: '00N1U00000VDW6q',
  PRODUCTKNOWLEDGE: '00N1U00000VUhPk',
  CUSTOMERPROFILE: '00N1U00000VDW75',
  CUSTOMERRELATIONSHIP: '00N1U00000VDW6s',
  WHEREFINDUS: '00N1U00000VDW6k',
  WHYWANTPARTNER: '00N1U00000VDW76',
  OTHERS: '00N1U00000VDW7B',
  HOWLONGBEENACTING: '00N1U00000VDW7H',
 

}

const PartnersForm = (props) => {
  const formRef = useRef(null)
  const [companyHasSite, setCompanyHasSite] = useState(false)
  const [step, setStep] = useState(StepEnum.FIRST)
  const [selectedActingType, setSelectedActingType] = useState(false)
  const [actingOthers, setActingOthers] = useState(false)
  const [personHasKnowledge, setPersonHasKnowledge] = useState(false)
  const ref = React.createRef()
  
  const link = document.location.href
  const executeScroll = useCallback(() => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }, [ref])
  

  const handleCompanyHasSite = useCallback((hasSite) => {
    if (hasSite) {
      formRef.current.setFieldValue(HashEnum.WEBSITE, '')
      formRef.current.setFieldError(HashEnum.WEBSITE, '')
    }

    setCompanyHasSite(hasSite)
  }, [])

  const handleActingOthers = (selectedOption) => {
    if (selectedOption.value === 'Outros') {
      setActingOthers(true)
      return
    }
    formRef.current.setFieldValue(HashEnum.OTHERS, '')
    formRef.current.setFieldError(HashEnum.OTHERS, '')
    setActingOthers(false)
  }

  const handleActingType = (selectedOption) => {
    if (selectedOption === 'Pessoa Física') {
      setSelectedActingType(false)
      return
    }
    setSelectedActingType(true)
  }

  const handlePersonHasKnowledge = (hasKnowledge) => {
    setPersonHasKnowledge(hasKnowledge === 'Sim')
    formRef.current.setFieldValue(HashEnum.PRODUCTKNOWLEDGE, hasKnowledge)
  }

  function validatePhone(phone) {
    var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((9[0-9]{3}[0-9]{5}))$')
    return regex.test(phone)
  }

  function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  }

  // Example POST method implementation:
  async function postData(url = '', data = {}) {
    // Default options are marked with *
    return await fetch(url, {
      method: 'POST',
      mode: 'no-cors', // Bypass cors
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(data), // body data type must match "Content-Type" header
    })
  }

  const fullSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Nome obrigatório. Tente novamente.'),
    last_name: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Sobrenome obrigatório. Tente novamente.'),
    email: Yup.string()
      .test('Valida e-mail', 'E-mail inválido. Tente novamente.', function () {
        return validateEmail(formRef.current.getFieldValue('email'))
      })
      .required('E-mail é obrigatório.'),
    mobile: Yup.string()
      .test(
        'Valida celular',
        'Celular inválido. Tente novamente.',
        function () {
          return validatePhone(
            formRef.current.getFieldValue('mobile').replace(/[^\d]/g, '')
          )
        }
      )
      .required('Celular é obrigatório.'),
    [HashEnum.CPF]: Yup.string()
      .test('Valida CPF', 'CPF inválido. Tente novamente.', function () {
        return cpf.isValid(formRef.current.getFieldValue(`${HashEnum.CPF}`))
      })
      .required('CPF é obrigatório.'),
    [HashEnum.WHATSCNPJ]:
      selectedActingType &&
      Yup.string().test(
        'Valida CNPJ',
        'CNPJ inválido. Tente novamente. ',
        function () {
          if (formRef.current.getFieldValue(HashEnum.WHATSCNPJ) !== '') {
            return cnpj.isValid(
              formRef.current.getFieldValue(HashEnum.WHATSCNPJ)
            )
          }
          return true
        }
      ),
    [HashEnum.WEBSITE]: companyHasSite
      ? Yup.string().matches(
          /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Site inválido. Tente novamente.'
        )
      : null,
    [HashEnum.PRODUCTKNOWLEDGE]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.HOWWORK]: Yup.string()
      .nullable()
      .required('Tipo de atuação é obrigatório.'),
    [HashEnum.COMPANYNAME]: Yup.string().required(
      'Nome da empresa é obrigatório.'
    ),
    [HashEnum.LINEBUSINESS]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.OTHERS]: Yup.string(),
    [HashEnum.ACTINGSTATE]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.HOWLONGBEENACTING]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção.'),
    [HashEnum.CUSTOMERPROFILE]: Yup.string().required(
      'Campo obrigatório. Escolha pelo menos uma opção.'
    ),
    [HashEnum.CUSTOMERRELATIONSHIP]: Yup.string().required(
      'Campo obrigatório. Escolha pelo menos uma opção.'
    ),
    [HashEnum.WHEREFINDUS]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção.'),
    [HashEnum.WHYWANTPARTNER]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção.'),
  })

  const firstSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Nome obrigatório. Tente novamente.'),
    last_name: Yup.string()
      .min(2, 'Digite pelo menos 2 letras.')
      .required('Sobrenome obrigatório. Tente novamente.'),
    email: Yup.string()
      .test('Valida e-mail', 'E-mail inválido. Tente novamente.', function () {
        return validateEmail(formRef.current.getFieldValue('email'))
      })
      .required('E-mail é obrigatório.'),
    mobile: Yup.string()
      .test(
        'Valida celular',
        'Celular inválido. Tente novamente.',
        function () {
          return validatePhone(
            formRef.current.getFieldValue('mobile').replace(/[^\d]/g, '')
          )
        }
      )
      .required('Celular é obrigatório.'),
    [`${HashEnum.CPF}`]: Yup.string()
      .test('Valida CPF', 'CPF inválido. Tente novamente. ', function () {
        return cpf.isValid(formRef.current.getFieldValue(`${HashEnum.CPF}`))
      })
      .required('CPF é obrigatório.'),
  })

  const secondSchema = Yup.object().shape({
    [HashEnum.WHATSCNPJ]:
      selectedActingType &&
      Yup.string().test(
        'Valida CNPJ',
        'CNPJ inválido. Tente novamente. ',
        function () {
          if (formRef.current.getFieldValue(HashEnum.WHATSCNPJ) !== '') {
            return cnpj.isValid(
              formRef.current.getFieldValue(HashEnum.WHATSCNPJ)
            )
          }
          return true
        }
      ),
    [HashEnum.WEBSITE]: companyHasSite
      ? Yup.string().matches(
          /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Site inválido. Tente novamente.'
        )
      : null,
    [HashEnum.PRODUCTKNOWLEDGE]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.HOWWORK]: Yup.string()
      .nullable()
      .required('Tipo de atuação é obrigatório.'),
    [HashEnum.COMPANYNAME]: Yup.string().required(
      'Nome da empresa é obrigatório.'
    ),
    [HashEnum.LINEBUSINESS]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.OTHERS]: Yup.string(),
    [HashEnum.ACTINGSTATE]: Yup.string().required(
      'Campo obrigatório. Escolha uma opção.'
    ),
    [HashEnum.HOWLONGBEENACTING]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção.'),
    [HashEnum.CUSTOMERPROFILE]: Yup.array().required(
      'Campo obrigatório. Escolha pelo menos uma opção.'
    ),
    [HashEnum.CUSTOMERRELATIONSHIP]: Yup.array().required(
      'Campo obrigatório. Escolha pelo menos uma opção.'
    ),
    [HashEnum.WHEREFINDUS]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção.'),
    [HashEnum.WHYWANTPARTNER]: Yup.string()
      .nullable()
      .required('Campo obrigatório. Escolha uma opção. '),
  })

  const validateOnBlur = async (event) => {
    const fieldName = event.currentTarget.id || event.target.id

    if (formRef.current.getFieldValue('mobile')) {
      const unmask = formRef.current
        .getFieldValue('mobile')
        .replace(/[^\d]/g, '')

      formRef.current.setFieldValue('mobile', unmask)
    }

    if (formRef.current.getFieldValue('first_name')) {
      const unNumber = formRef.current
        .getFieldValue('first_name')
        .replace(/\d/g, '')

      formRef.current.setFieldValue('first_name', unNumber)
    }

    if (formRef.current.getFieldValue('last_name')) {
      const unNumber = formRef.current
        .getFieldValue('last_name')
        .replace(/\d/g, '')

      formRef.current.setFieldValue('last_name', unNumber)
    }

    try {
      formRef.current.setFieldError(fieldName, '')
      await Yup.reach(fullSchema, fieldName).validate(
        formRef.current.getFieldValue(fieldName),
        {
          abortEarly: false,
        }
      )
    } catch (err) {
      const validationError = []
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationError.path = error.message
        })
        formRef.current.setFieldError(fieldName, validationError.path)
      }
    }
  }

  const handleBackStep = () => {
    setStep(StepEnum.FIRST)
  }

  const handleNextStep = useCallback(
    async (data) => {
      if (formRef.current.getFieldValue('mobile')) {
        const unmask = formRef.current
          .getFieldValue('mobile')
          .replace(/[^\d]/g, '')

        formRef.current.setFieldValue('mobile', unmask)
      }
      try {
        formRef.current.setErrors({})
        await firstSchema.validate(formRef.current.getData(), {
          abortEarly: false,
        })

        setStep(StepEnum.SECOND)
      } catch (err) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [firstSchema]
  )

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({})

        await secondSchema.validate(formRef.current.getData(), {
          abortEarly: false,
        })

        if (formRef.current.getFieldValue('mobile')) {
          const unmask = formRef.current
            .getFieldValue('mobile')
            .replace(/[^\d]/g, '')

          formRef.current.setFieldValue('mobile', unmask)
        }

        const dataModified = await formRef.current.getData()

        await postData(process.env.REACT_APP_URL_SALESFORCE, dataModified)
          .then((data) => {})
          .catch((err) => console.log(err))

        executeScroll()
        setStep(StepEnum.FINISHED)
      } catch (err) {
        const validationErrors = {}

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    },
    [secondSchema, executeScroll]
  )

  const renderForm = () => {
    return (
      <>
        <h1>Seja nosso parceiro</h1>
        <FormWrapper>
          <VerticalLine />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormStep completed={step === StepEnum.SECOND}>
              <FormStepNumber>
                {step === StepEnum.SECOND ? (
                  <img src={checkIconWhite} alt="Ícone de Check" />
                ) : (
                  1
                )}
              </FormStepNumber>
              <FormStepData completed={step === StepEnum.SECOND}>
                <h2>Dados pessoais</h2>
                {step === StepEnum.SECOND ? (
                  // eslint-disable-next-line
                  <a onClick={handleBackStep}>Editar</a>
                ) : (
                  <span>Etapa 1 de 2</span>
                )}
              </FormStepData>   
            </FormStep>
            <Step isHidden={step !== StepEnum.FIRST}>
              <Input
                type="hidden"
                containerType="hidden"
                name="oid"
                value={process.env.REACT_APP_OID}
              />
              

              <Input
                type="hidden"
                containerType="hidden"
                name="retURL"
                value="https://parceirosbari.com.br"
              />

              <Input
                type="hidden"
                containerType="hidden"
                name="lead_source"
                value="Site"
              />              

              <Input
                type="hidden"
                containerType="hidden"
                name="recordType"
                id="recordTypeId"
                value={HashEnum.RECORDTYPEID}
              />

              <Input
                type="hidden"
                containerType="hidden"
                id={HashEnum.LEADORIGIN}
                name={HashEnum.LEADORIGIN}
                title="Origem do Lead"
                value="Parceiro"
              />
              <Input
              type="hidden"
              containerType="hidden"
              id={HashEnum.ORIGINSIMULATOR}
              name={HashEnum.ORIGINSIMULATOR}
              title="Simulador de origem"            
              value={link}              
              />
              
              
            

              <FlexDiv>
                <Input
                  id="first_name"
                  label="Nome"
                  name="first_name"
                  size="20"
                  type="text"
                  placeholder="Ex: João"
                  onBlur={validateOnBlur}
                />

                <Input
                  id="last_name"
                  label="Sobrenome"
                  name="last_name"
                  size="20"
                  type="text"
                  placeholder="Ex: Da Silva"
                  onBlur={validateOnBlur}
                />
              </FlexDiv>

              <Input
                id="email"
                name="email"
                label="E-mail"
                size="20"
                type="text"
                placeholder="Ex: nome@email.com.br"
                onBlur={validateOnBlur}
              />
              <MarginBottom24 />

              <MaskedInput
                id="mobile"
                name="mobile"
                label="Celular"
                size="20"
                type="text"
                mask="(99) 99999-9999"
                placeholder="(XX) 9 XXXX XXXX"
                onBlur={validateOnBlur}
              />
              <MarginBottom24 />

              <MaskedInput
                id={HashEnum.CPF}
                name={HashEnum.CPF}
                label="CPF"
                size="20"
                type="text"
                mask="999.999.999-99"
                placeholder="000.000.000-00"
                onBlur={validateOnBlur}
              />
              <MarginBottom24 />

              <Button
                full
                black
                id="handle-next-step"
                onClick={() => handleNextStep()}
              >
                Próxima etapa <img src={arrowRight} alt="Arrow right" />
              </Button>
            </Step>
            <FormStep isSecondStep={true}>
              <FormStepNumber>2</FormStepNumber>
              <FormStepData waiting={step === StepEnum.FIRST}>
                <h2>Sobre a sua atuação</h2>
                <span>Etapa 2 de 2</span>
              </FormStepData>
            </FormStep>
            <Step isHidden={step !== StepEnum.SECOND}>
              <h3>Como você atua?</h3>

              <RadioList
                id={HashEnum.HOWWORK}
                name={HashEnum.HOWWORK}
                title="Forma de atuação"
                label="Como você atua?"
                options={actingTypeOptions}
                onChange={handleActingType}
              />

              <MarginBottom24 />

              {selectedActingType && (
                <>
                  <MaskedInput
                    id={HashEnum.WHATSCNPJ}
                    name={HashEnum.WHATSCNPJ}
                    label="Qual o CNPJ?"
                    size="20"
                    type="text"
                    mask="99.999.999/9999-99"
                    placeholder="00.000.000/0000-00"
                    onBlur={validateOnBlur}
                  />
                  <MarginBottom24 />
                </>
              )}

              <>
                <ChoiceButtons companyHasSite={companyHasSite}>
                  <label>
                    Sua empresa tem site? <span>Opcional</span>
                  </label>
                  <ButtonGroup>
                    <Button
                      fixedWidth
                      black={companyHasSite}
                      white={!companyHasSite}
                      id="handle-has-site"
                      onClick={() => handleCompanyHasSite(true)}
                    >
                      Sim
                    </Button>

                    <Button
                      fixedWidth
                      black={!companyHasSite}
                      white={companyHasSite}
                      id="handle-has-no-site"
                      onClick={() => handleCompanyHasSite(false)}
                    >
                      Não
                    </Button>
                  </ButtonGroup>
                </ChoiceButtons>
                <MarginBottom24 />

                <>
                  <Input
                    id={HashEnum.WEBSITE}
                    name={HashEnum.WEBSITE}
                    label="Se sim, insira o domínio"
                    size="20"
                    type="text"
                    disabled={!companyHasSite}
                    isDisabled={!companyHasSite}
                    placeholder="www.empresa.com.br"
                    onBlur={validateOnBlur}
                  />
                  <MarginBottom24 />
                </>

                <Input
                  id={HashEnum.COMPANYNAME}
                  name={HashEnum.COMPANYNAME}
                  label="Nome da empresa"
                  size="20"
                  type="text"
                  placeholder="Nome da empresa"
                  onBlur={validateOnBlur}
                />
                <MarginBottom24 />
              </>

              <SelectContainer
                id={HashEnum.LINEBUSINESS}
                name={HashEnum.LINEBUSINESS}
                title="Ramo de atuação"
                label="Selecione o ramo de atuação"
                options={actingFieldOptions}
                placeholder="Selecione um ramo de atuação"
                onBlur={validateOnBlur}
                mask={/^([^0-9]*)$/}
                maskChar={null}
                onChange={handleActingOthers}
              />
              <MarginBottom24 />

              {actingOthers && (
                <>
                  <Input
                    id={HashEnum.OTHERLINEBUSINESS}
                    name={HashEnum.OTHERLINEBUSINESS}
                    label="Digite o seu ramo de atuação"
                    size="20"
                    type="text"
                    placeholder="Ex: Se digitar mais de um, separe por vírgulas"
                    onBlur={validateOnBlur}
                  />
                  <MarginBottom24 />
                </>
              )}

              <SelectContainer
                id={HashEnum.ACTINGSTATE}
                name={HashEnum.ACTINGSTATE}
                title="Estado que atua"
                label="Qual seu principal estado de atuação"
                options={ufOptions}
                placeholder="Selecione um estado"
                onBlur={validateOnBlur}
              />
              <MarginBottom24 />

              <>
                <ChoiceButtons personHasKnowledge={personHasKnowledge}>
                  <label>
                    Você trabalha ou conhece o produto de crédito com garantiade
                    imóvel?
                  </label>
                  <ButtonGroup>
                    <Button
                      fixedWidth
                      black={personHasKnowledge}
                      white={!personHasKnowledge}
                      id="handle-has-knowledge"
                      onClick={() => handlePersonHasKnowledge('Sim')}
                    >
                      Sim
                    </Button>

                    <Button
                      fixedWidth
                      black={!personHasKnowledge}
                      white={personHasKnowledge}
                      id="handle-has-knowledge"
                      onClick={() => handlePersonHasKnowledge('Não')}
                    >
                      Não
                    </Button>
                  </ButtonGroup>
                </ChoiceButtons>
                <Input
                  type="hidden"
                  containerType="hidden"
                  id={HashEnum.PRODUCTKNOWLEDGE}
                  name={HashEnum.PRODUCTKNOWLEDGE}
                  title="Conhecimento sobre o produto"
                  value={personHasKnowledge ? 'Sim' : 'Não'}
                />
                <MarginBottom24 />
              </>

              <h3>Há quanto tempo você atua?</h3>

              <RadioList
                id={HashEnum.HOWLONGBEENACTING}
                name={HashEnum.HOWLONGBEENACTING}
                title="Tempo de atuação no ramo"
                label="Há quanto tempo você atua?"
                options={actingTimeOptions}
              />
              <MarginBottom24 />

              <h3>Selecione o perfil dos seus clientes</h3>

              <CheckboxList
                id={HashEnum.CUSTOMERPROFILE}
                name={HashEnum.CUSTOMERPROFILE}
                options={clientProfileOptions}
              />
              <MarginBottom24 />

              <h3>Como você inicia uma relação com seus clientes?</h3>

              <CheckboxList
                id={HashEnum.CUSTOMERRELATIONSHIP}
                name={HashEnum.CUSTOMERRELATIONSHIP}
                options={clientFontOptions}
              />
              <MarginBottom24 />

              <h3>Como você nos encontrou?</h3>

              <RadioList
                id={HashEnum.WHEREFINDUS}
                name={HashEnum.WHEREFINDUS}
                title="Como nos encontrou"
                label="Como você nos encontrou?"
                options={howFindUsOptions}
              />
              <MarginBottom24 />

              <h3>Por que quer ser nosso parceiro?</h3>

              <RadioList
                id={HashEnum.WHYWANTPARTNER}
                name={HashEnum.WHYWANTPARTNER}
                title="Por que quer ser nosso parceiro"
                label="Por que quer ser nosso parceiro?"
                options={whyBeOurPartnerOptions}
              />
              <MarginBottom24 />

              <ButtonWrapper>
                <Button
                  full
                  black
                  id="want-to-be-partner"
                  type="submit"
                  name="submit"
                  onClick={() => (handleNextStep())}
                >
                  Quero ser Parceiro Bari!
                  <img src={arrowRight} alt="Arrow right" />
                </Button>
                
              </ButtonWrapper>
            </Step>
          </Form>
        </FormWrapper>
      </>
    )
  }

  return (
    <Container ref={ref}>
      <Content ref={props.refProp} step={step}>
        {step !== StepEnum.FINISHED ? (
          renderForm()
        ) : (
          <SucessStep>
            <img src={thumbsCheck} alt="Check e thumbsup" />
            <span>Pronto! Agora é só aguardar o nosso contato!</span>
           
            
          </SucessStep>
        )}
      </Content>
    </Container>
  )
}

export default PartnersForm

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label:last-child {
    margin-bottom: 0;
  }
`;

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Nunito', sans-serif;
  padding: 4px 0 !important;
  font-size: 10px !important;
`;

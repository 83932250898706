// ASSETS
import boardIcon from "../assets/board-icon.svg";
import dashboardIcon from "../assets/dashboard-icon.svg";
import headIcon from "../assets/head-icon.svg";
import houseIcon from "../assets/house-icon.svg";

export const AdvantagesList = [
  {
    title: "Linha de Crédito com Garantia de Imóvel",
    conditions: [
      {
        condition: "1,09",
        separator: "%",
        description: "A partir de 1,09% ao mês + IPCA.",
      },
      {
        condition: "20",
        separator: " anos",
        description: "Pagamento em até 240 meses na tabela SAC.",
      },
      {
        condition: "6",
        separator: " meses",
        description: "Até seis meses para começar a pagar.",
      },
      {
        condition: "60",
        separator: "%",
        description: "Linha de crédito de até 60% do valor do imóvel",
      },
    ],
  },
  {
    title: "Barifin",
    conditions: [
      {
        condition: "1,07",
        separator: "%",
        description: "A partir de 1,07% ao mês + IPCA.",
      },
      {
        condition: "20",
        separator: " anos",
        description: "Pagamento em até 240 meses.",
      },
      {
        condition: "3",
        separator: " meses",
        description: "Até três meses para começar a pagar.",
      },
      {
        condition: "70",
        separator: "%",
        description: "Linha de crédito de até 70% do valor do imóvel",
      },
    ],
  },
];

export const PartnerAdvantagesList = [
  {
    icon: headIcon,
    title: "Produtos Inteligentes.",
    advantages: [
      "Produtos de crédito pensados para a transformação da vida financeira de nossos clientes.",
      "Estímulo do crédito saudável e consciente faz parte de nosso DNA.",
      "Linha de Crédito com Garantia de Imóvel: dinheiro na conta ou cartão Baricard Imobiliário.",
      "Baricard Imobiliário: o primeiro cartão imobiliário do Brasil.",
    ],
  },
  {
    icon: boardIcon,
    title: "Comissões e campanhas de incentivo.",
    advantages: [
      "Comissões mais competitivas do mercado.",
      "Campanhas de incentivo para alcançar suas metas.",
      "Materiais de apoio à venda.",
      "Treinamentos para desenvolvimento de seus negócios.",
    ],
  },
  {
    icon: houseIcon,
    title: "Experiência em empréstimo com garantia de imóvel.",
    advantages: [
      "Mais de 10 anos de experiência na operação de crédito imobiliário.",
      "Ampla rede de parceiros.",
      "Consultores comerciais especializados para ajudar nos negócios.",
      "Mais de R$ 1 bilhão concedido em crédito imobiliário.",
    ],
  },
  {
    icon: dashboardIcon,
    title: "Praticidade com o Portal de Parceiros Bari.",
    advantages: [
      "Portal digital para simulação de crédito.",
      "Cadastro e acompanhamento de propostas de maneira simples e rápida.",
      "Relatórios completos para acompanhar seus ganhos.",
      "Capacitações e treinamentos para ajudar você a vender mais.",
    ],
  },
];

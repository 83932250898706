import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-11
 */
const FieldContainer = styled.div`
  ${media.lessThan('medium')`
        margin-bottom: 28px;
    `}
  ${media.greaterThan('medium')`
        margin-bottom: 32px;
    `}
`;

export default FieldContainer;

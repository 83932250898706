import styled, { css } from 'styled-components';

// ASSETS
import arrowRight from '../../../static/images/arrow-right.svg';
import arrowLeft from '../../../static/images/arrow-left.svg';

// ENUMS
import { sideButton } from '@bancobarigui/reactbook';

export const ButtonArrows = styled.div`
  > span {
    display: inline-block;
    margin-left: 2px;
    width: 5px;
    height: 12px;
    animation: animate 2s infinite;

    ${({ sideIcon }) =>
      sideIcon === sideButton.LEFT &&
      css`
        background: url(${arrowLeft});

        &:nth-child(2) {
          animation-delay: -0.2s;
        }
        &:nth-child(3) {
          animation-delay: -0.4s;
        }
        &:nth-child(4) {
          animation-delay: -0.6s;
        }
        &:nth-child(5) {
          animation-delay: -0.8s;
        }
      `}

    ${({ sideIcon }) =>
      sideIcon === sideButton.RIGHT &&
      css`
        background: url(${arrowRight});

        &:nth-child(2) {
          animation-delay: -0.8s;
        }
        &:nth-child(3) {
          animation-delay: -0.6s;
        }
        &:nth-child(4) {
          animation-delay: -0.4s;
        }
        &:nth-child(5) {
          animation-delay: -0.2s;
        }
      `}
  }
`;

import React, { useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';

// COMPONENTS
import AccessSection from './components/AccessSection';
import WarningSection from './components/WarningSection';
import IntroSection from './components/IntroSection';

// COMPONENTS UI
import withLayout from '../../components/UI/Layout';
import Section from '../../components/UI/Section';

// STATIC
import homeImage from '../../static/images/home.png';
import homeIcone from '../../static/images/icons/icon-home.svg';
import dashboardImage from '../../static/images/dashboard.png';
import graphIcon from '../../static/images/icons/icon-graph.svg';
import proposalResume from '../../static/images/proposal-resume.png';
import iconEye from '../../static/images/icons/icon-eye.svg';
import documentsTutorials from '../../static/images/documents-tutorials.png';
import iconKnowledge from '../../static/images/icons/icon-knowledge.svg';

const Newsletter = () => {
  const refSection = useRef(null);

  const executeScroll = useCallback(() => {
    refSection.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Confira as novidades no Portal de Parceiros - Banco Bari</title>
      </Helmet>

      <IntroSection onClick={executeScroll} />

      <Section
        sectionImage={homeIcone}
        imagem={homeImage}
        message={`A tela inicial do Portal de Parceiros está de cara nova. Redesenhamos essa página para mostrar as últimas atualizações sobre o nosso negócio, além de trazer novidades que serão importantes para a sua rotina, como porcentagens de comissionamentos e muito mais.`}
        sectionTitle="Home renovada"
        ref={refSection}
        title="Novo visual para a tela inicial"
      />

      <Section
        revertSideInMobile={true}
        imageSide="left"
        sectionImage={graphIcon}
        imagem={dashboardImage}
        message={`Criamos um painel na tela inicial que traz o resumo dos seus negócios. Nele você vai encontrar o número de propostas que estão em cada fase da esteira, o valor total e médio das propostas ativas, além do tempo médio desde o envio para a pré-análise até o arquivamento delas.`}
        title="Painel de atualização dos seus negócios"
        sectionTitle="Controle de resultados"
        ref={refSection}
      />

      <Section
        sectionImage={iconEye}
        imagem={proposalResume}
        message={`Fizemos uma melhoria na área de acompanhamento de propostas. Agora, você irá visualizar ali as atualizações das suas últimas quatro propostas cadastradas. Dessa forma, terá mais controle sobre o status de cada uma e saberá de forma rápida quando há pendências ou novidades.`}
        sectionTitle="Informações dinâmicas"
        title="Atualização da área de acompanhamento de propostas"
        ref={refSection}
      />

      <Section
        revertSideInMobile={true}
        imageSide="left"
        sectionImage={iconKnowledge}
        imagem={documentsTutorials}
        message={`Desenvolvemos uma nova página para reunir alguns materiais importantes para a sua rotina de negócios. Na nova área de vídeos e tutoriais você vai encontrar os documentos necessários para o dia a dia, como o guia de emissão de NF de comissão e a apresentação institucional.  Além disso, remodelamos a área de vídeos para que você tenha acesso às nossas lives, Encontros de Negócios e tutoriais que irão te ajudar a utilizar melhor as nossas ferramentas.`}
        title="Nova página de documentos, vídeos e tutoriais"
        sectionTitle="Capacitação e auxílio"
        ref={refSection}
      />
      <WarningSection />
      <AccessSection />
    </>
  );
};

export default withLayout({ header: true, footer: true })(Newsletter);

import React from 'react';
import Modal from '../../../components/UI/Modal';
import { ContentModal, TitleModal, TextModal } from './styles';
import { BrokenLink } from './svgs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-27
 */
const ModalExpired = ({ active }) => {
  return (
    <Modal active={active}>
      <ContentModal>
        <BrokenLink />
        <TitleModal>O link para a assinatura digital expirou.</TitleModal>
        <TextModal>
          Entre em contato com nosso parceiro e peça um novo link.
        </TextModal>
      </ContentModal>
    </Modal>
  );
};

export default ModalExpired;

import React, { useEffect } from 'react';
import { ContentModal, Container } from './style';

const Modal = props => {
  const { children, active } = props;

  useEffect(() => {
    if (active) {
      window.scrollTo(0, 0);
      document.body.style.overflowY = 'hidden';
      window.onbeforeunload = () => {
        window.scrollTo(0, 0);
      };
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [active]);

  return (
    <ContentModal active={active}>
      <Container>{children}</Container>
    </ContentModal>
  );
};

export default Modal;

export const getTerms = async (endpoint) => {
  const url = process.env.REACT_APP_API;
  const response = await fetch(`${url}/terms/v1/terms/${endpoint}`);
  const data = await response.json();

  if (data) {
    return data;
  }

  return null;
};

import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-12-14
 */
const Container = styled.div`
  opacity: 0;
  z-index: 99999;
  position: fixed;
  display: flex;
  align-items: center;
  right: 2%;
  bottom: 2%;
  padding: 24px;
  border: 1px solid #f6f6f6;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #555555;
  transition: opacity 0.65s ease-in;
  &.Active {
    opacity: 1;
  }
`;

const Button = styled.div`
  cursor: pointer;
  color: #0090ff;
  margin-left: 28px;
  > svg {
    margin-left: 10px;
  }
`;

export { Container, Button };

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  img {
    width: 78%;
    position: relative;
    top: -100px;
    left: 11%;
    @media screen and (max-width: 1500px) {
      max-height: 600px;
      width: 90% !important;
      left: 5% !important;
    }
    @media screen and (max-width: 568px) {
      width: 82% !important;
      left: 9% !important;
      top: -100px !important;
    }
  }

  @media screen and (max-width: 490px) {
    margin-bottom: 0;

    img {
      max-width: 82% !important;
      left: 9% !important;
      top: -75px !important;
    }
  }
`;

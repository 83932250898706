import React from 'react';

// COMPONENTS
import Card from '../../../../components/UI/Card';

// COMPONENTS
import { AdvantagesList } from '../../../../constants/advantages';

import {
  Container,
  Content,
  ContentWrapper,
  KnowAdvantages,
  Line,
  TitleContainer,
} from './styles';

const AdvantagesContainer = () => {
  const renderSpanTitle = (title, span) => {
    return (
      <>
        {title}
        <span>{span}</span>
      </>
    );
  };

  const renderLine = (items, isDark) => {
    return (
      <Line>
        {items.map((item, index) => {
          return (
            <Card
              description={item.description}
              key={`card-${index}`}
              title={renderSpanTitle(item.condition, item.separator)}
              type={isDark ? 'dark' : 'default'}
            />
          );
        })}
      </Line>
    );
  };

  const renderContent = (content, isDark) => {
    const conditions = content.conditions;

    return (
      <Content>
        <TitleContainer>
          <h3>{content.title}</h3>
        </TitleContainer>
        {renderLine(conditions.slice(0, 2), isDark)}
        {renderLine(conditions.slice(2), isDark)}
      </Content>
    );
  };

  return (
    <Container>
      <KnowAdvantages>
        <strong>Conheça as vantagens</strong> de nossos produtos
      </KnowAdvantages>

      <ContentWrapper>
        {AdvantagesList.map((advantage, index) => {
          return (
            <React.Fragment key={index}>
              {renderContent(advantage, index > 0)}
            </React.Fragment>
          );
        })}
      </ContentWrapper>
    </Container>
  );
};

export default AdvantagesContainer;

import React from 'react';
import Step from './Step';
import { StyledSteps } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const Steps = ({ items }) => {
  return (
    <StyledSteps>
      {items.map((item, idx) => {
        return (
          <Step
            key={`step-${idx + 1}`}
            title={item.title}
            content={item.content}
            link={item.link}
          />
        );
      })}
    </StyledSteps>
  );
};

export default Steps;

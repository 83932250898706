import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const MenuStyled = styled.ul`
  list-style: none;
`;

const MenuItemStyled = styled.li`
  ${media.lessThan('medium')`
        margin-bottom: 24px;
        display: block;
    `}
  ${media.greaterThan('medium')`
        display: inline-block;
        ${props =>
          props.horizontal &&
          `
            &:not(:last-child) {
                margin-right: 53px;
            }
        `};
    `};
`;

const MenuItemLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  text-decoration: none;
  font-family: 'Clan Web Pro News', sans-serif;
`;

export { MenuStyled, MenuItemStyled, MenuItemLink };

import styled from 'styled-components';
import media from 'styled-media-query';
import backgroundImage from '../../../static/images/background.jpg';

export const ButtonContainer = styled.div`
  margin-top: 32px;

  & button {
    border: 1px solid;
  }
`;

const IntroWrapper = styled.div`
  margin-top: 106px;
`;

const FormWrapper = styled.div`
  ${media.lessThan('medium')`
        margin: 64px 0 24px 0;
    `}
  ${media.greaterThan('medium')`
        margin: 96px 0;
    `}
`;

const AboutWrapper = styled.div`
  ${media.lessThan('medium')`
        padding: 0 13px;
        margin: 24px 0 52px 0;
    `}
  ${media.greaterThan('medium')`
        margin: 104px 0;
    `}
`;

const StepsWrapper = styled.div`
  ${media.greaterThan('medium')`
        padding-left: 90px;
    `}
`;

const Text = styled.p`
  font-size: 10px;
  line-height: 20px;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;

const BackgroundImage = styled.div`
  position: relative;
  margin-top: 78px;
  ${media.lessThan('medium')`
        height: 264px;
    `}
  ${media.greaterThan('medium')`
        height: 450px;
    `}
    background: #101010;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(${backgroundImage});
    ${media.greaterThan('medium')`
            background-attachment: fixed;
        `};
    background-position-y: 25%;
    background-size: cover;
    opacity: 25%;
  }
`;

const SecurityWrapper = styled.div`
  ${media.lessThan('medium')`
        padding: 76px 0 72px 0;
    `}
  ${media.greaterThan('medium')`
        padding: 0px 0px;
    `}
    background: #101010;
`;

const SecureAmbience = styled.label`
  display: flex;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 10px;
  line-height: 16px;
  color: #e5e5e5;
  > img {
    margin-right: 8px;
  }
`;

const TitleWrapper = styled.div`
  margin: 31px 0 24px 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 64px;
`;

const ContentModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleModal = styled.h2`
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin-top: 39px;
  margin-bottom: 32px;
  font-family: 'Clan Web Pro Medium';
`;

const TextModal = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro News';
  width: 80%;
  max-width: 420px;
  text-align: center;
  margin-bottom: 24px;

  & > button {
    border: 1px solid #646464;
    margin: 40px auto 0 auto;
  }
`;

const FooterModal = styled.div`
  position: absolute;
  bottom: 40px;
`;

const ComponentCodeActivation = styled.div`
  height: 100vh;
  background-color: #101010;
  .minContainer {
    max-width: 624px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &::after {
      content: inherit;
    }
    &::before {
      content: inherit;
    }
  }
`;

const TitleResponse = styled.h2`
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin-top: 39px;
  margin-bottom: 32px;
  font-family: 'Clan Web Pro Medium';
  color: #ffffff;
`;

const StyleResponse = styled.div`
  background-color: #101010;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TextResponse = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro News';
  width: 80%;
  text-align: center;
  margin-bottom: 24px;
  color: #8e8e8e;

  &.white {
    color: #ffffff;
  }
`;

const ContentResponse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    position: absolute;
    bottom: 32px;
  }
`;

const Back = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;
  cursor: pointer;
  ${media.lessThan('medium')`
    margin-top: 3vh;
  `};
  svg {
    margin-right: 8px;
  }
`;

const TextBack = styled.a`
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
  font-family: 'Clan Web Pro Medium';
`;

const ImageWrapper = styled.div`
  margin-top: 16px;
  > img {
    width: 120px;
  }
`;

const ContentActiveCode = styled.div`
  background: #212121;
  border: 1px solid #292929;
  box-sizing: border-box;
  box-shadow: 0px 34px 50px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 48px;

  .iconHeder {
    margin-bottom: 32px;
  }
`;

const HeaderActiveCode = styled.div`
  margin-top: 32px;
  ${media.lessThan('medium')`
    margin-top: 10px;
  `};
`;

const TitleActiveCode = styled.h2`
  color: #ffffff;
  font-size: 28px;
  line-height: 40px;
  font-family: 'Clan Web Pro News';
  font-weight: 500;
  margin-bottom: 8px;
`;

const SubtitleActiveCode = styled.p`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-family: 'Clan Web Pro News';
  text-align: left;
  margin-bottom: 24px;
  color: #8e8e8e;
  span {
    color: #c2c2c2;
    margin-left: 4px;
  }
`;

const ContentInput = styled.div`
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
    margin-bottom: 0;
  }
  ${media.lessThan('medium')`
    flex-wrap: wrap;
  `};
  input {
    margin-bottom: 0;
    border-radius: 8px 0px 0px 8px;
    color: #101010;
    letter-spacing: 5px;
    &::placeholder {
      letter-spacing: 0;
    }
    ${media.lessThan('medium')`
        border-radius: 8px;
    `};
  }
`;

const Button = styled.button`
  cursor: pointer;
  background: #489d83;
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 0px;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  color: #ffffff;
  border: none;
  padding: 21px 30px;
  &:disabled {
    cursor: not-allowed;
    background: #8e8e8e;
  }

  ${media.lessThan('medium')`
    margin-top: 16px;
    margin-bottom: 0px;
    padding: 17px 39px;
    border-radius: 8px;
    width: 100%;
  `}
`;

const RefreshCode = styled.div`
  display: flex;
  justify-content: end;
  cursor: pointer;
  margin-top: 50px;
  svg {
    margin-right: 8px;
  }
  ${media.lessThan('medium')`
    margin-top: 20px;
  `};
  &.Disabled {
    > p {
      color: #555555;
      cursor: not-allowed;
    }
    > svg {
      cursor: not-allowed;
      > path {
        stroke: #555555;
      }
    }
  }
`;

const RefreshCodeText = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  color: #0090ff;
  span {
    color: #0090ff;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 31px;
  padding-right: 15px;
  padding-left: 15px;
  img {
    margin-right: 8px;
  }
`;

const FooterText = styled.p`
  font-size: 10px;
  line-height: 16px;
  font-family: 'Clan Web Pro Medium';
  color: #8e8e8e;
`;

export {
  IntroWrapper,
  FormWrapper,
  StepsWrapper,
  AboutWrapper,
  Text,
  Back,
  TextBack,
  BackgroundImage,
  SecurityWrapper,
  SecureAmbience,
  TitleWrapper,
  ButtonWrapper,
  ContentModal,
  TitleModal,
  TextModal,
  FooterModal,
  ImageWrapper,
  ContentActiveCode,
  HeaderActiveCode,
  TitleActiveCode,
  SubtitleActiveCode,
  ContentInput,
  Button,
  RefreshCode,
  RefreshCodeText,
  Footer,
  FooterText,
  ComponentCodeActivation,
  StyleResponse,
  ContentResponse,
  TextResponse,
  TitleResponse,
};

import styled, { css } from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;

  button + button {
    margin-left: 16px;
  }

  @media screen and (max-width: 768px) {
    display: block;

    button + button {
      margin-left: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    margin-bottom: 0;
    margin-top: 30px;
  }

  @media screen and (max-width: 768px) {
    & button {
      width: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f6f6f6;
  padding: 160px 0;
  width: 100%;
  z-index: 9999;

  h1 {
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 90px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0;

    h1 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 70px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    max-width: 449px;

    button {
      margin: 40px 0 60px;
    }
  }

  @media screen and (max-width: 768px) {
    form {
      max-width: 449px;

      button {
        width: 100%;
        margin: 40px 0 0;
      }

      button + button {
        margin: 16px 0 48px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 70px;
    }
  }
`;

export const ChoiceButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 18px;
    margin-bottom: 10px;

    span {
      color: #8e8e8e;
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  c div {
    align-self: flex-start;
  }

  div + div {
    margin-left: 16px;
  }
`;

export const FormStep = styled.div`
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;

  ${({ isSecondStep }) =>
    isSecondStep &&
    css`
      border-top: 1px solid #c2c2c2;
      padding-top: 32px;

      & div:first-child {
        background-color: #f6f6f6;
        color: #c2c2c2;
        border: 1px solid #c2c2c2;
      }
    `};

  ${({ completed }) =>
    completed &&
    css`
      & div:first-child {
        background-color: #8e8e8e;
        border: 1px solid #8e8e8e;
      }
    `};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
`;

export const FormStepData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 34px;
  width: 100%;

  h2 {
    font-size: 32px;
    font-weight: 600;
    text-align: left;
  }

  span {
    font-size: 18px;
    color: #c2c2c2;
  }

  ${({ completed }) =>
    completed &&
    css`
      h2 {
        color: #989898;
      }

      a {
        color: #0090ff;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
      }
    `}

  ${({ waiting }) =>
    waiting &&
    css`
      h2 {
        color: #989898;
      }
    `}
   

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    h2 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const FormStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  margin-left: -67px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 16px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    width: 82%;
  }
`;

export const MarginBottom24 = styled.div`
  margin-bottom: 24px;
  margin-top: 0px;
  width: 100%;
`;

export const Step = styled.div`
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const SucessStep = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 490px;

  @media screen and (max-width: 768px) {
    img {
      width: 90px;
      height: 109px;
    }
  }

  span {
    margin-top: 56px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid #c2c2c2;
  padding-left: 50px;
  margin-bottom: 60px;
  margin-top: 10px;
  ${({ step }) =>
    step === 'second' &&
    css`
      height: 105px;
    `}

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

import React from 'react';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-12-14
 */
const Close = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.29289 2.13568C9.68342 1.74515 9.68342 1.11199 9.29289 0.721464L9.27853 0.707106C8.88801 0.316582 8.25485 0.316582 7.86432 0.707107L5.70711 2.86432C5.31658 3.25485 4.68342 3.25485 4.29289 2.86432L2.13568 0.707109C1.74515 0.316584 1.11199 0.316585 0.721464 0.70711L0.707106 0.721467C0.316582 1.11199 0.316582 1.74516 0.707107 2.13568L2.86432 4.29289C3.25484 4.68342 3.25484 5.31658 2.86432 5.70711L0.707108 7.86432C0.316584 8.25484 0.316583 8.88801 0.707108 9.27853L0.721466 9.29289C1.11199 9.68341 1.74515 9.68341 2.13568 9.29289L4.29289 7.13568C4.68342 6.74515 5.31658 6.74515 5.70711 7.13568L7.86432 9.29289C8.25485 9.68342 8.88801 9.68342 9.27853 9.29289L9.29289 9.27853C9.68342 8.88801 9.68342 8.25485 9.29289 7.86432L7.13568 5.70711C6.74515 5.31658 6.74515 4.68342 7.13568 4.29289L9.29289 2.13568Z"
      fill="#0090FF"
    />
  </svg>
);

export default Close;

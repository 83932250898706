import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-13
 */
const Image = styled.img`
  display: inline-block;
  ${media.lessThan('medium')`
        ${props =>
          props.isDesktop &&
          `
             display: none;
        `};    
    `}
  ${media.greaterThan('medium')`
        ${props =>
          props.isMobile &&
          `
             display: none;
        `};    
    `}
`;

export default Image;

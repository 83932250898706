import React from 'react';
import MenuItem from './MenuItem';
import { MenuStyled } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const Menu = props => {
  const { items, horizontal } = props;

  return (
    <MenuStyled>
      {items.map((item, idx) => (
        <MenuItem
          key={`menu-${idx + 1}`}
          path={item.path}
          label={item.label}
          horizontal={horizontal}
        />
      ))}
    </MenuStyled>
  );
};

export default Menu;

import React from 'react';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';
import FieldContainer from '../FieldContainer';
import { StyledMaskedInput, Hint } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const MaskedInput = props => {
  const {
    label,
    name,
    mask,
    readOnly,
    error = null,
    hint = null,
    ...rest
  } = props;
  const hasHint = hint !== null;
  const hasError = error !== null;

  return (
    <FieldContainer error={error}>
      <Label
        htmlFor={name}
        readOnly={readOnly}
        hasHint={hasHint}
        hasError={hasError}
      >
        {label}
      </Label>
      {hasHint && <Hint readOnly={readOnly}>{hint}</Hint>}
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
      <StyledMaskedInput
        name={name}
        mask={mask}
        hasError={hasError}
        readOnly={readOnly}
        {...rest}
      />
    </FieldContainer>
  );
};

export default MaskedInput;

import { useState, useEffect } from "react";

import { TERM } from "../../../constants/terms";

import logoBari from "../../../static/images/logo-white.svg";

import { getTerms } from "../../../utils/getTerms";

import { Col, Container, Row } from "../../Grid";
import Image from "../Image";
import Menu from "../Menu";

import { Copyright, FooterWrapper, MenuWrapper } from "./style";

const Footer = () => {
  const [menuItems, setMenuItens] = useState([]);

  useEffect(() => {
    const getTermMenu = async () => {
      try {
        const data = await Promise.all([
          getTerms(TERM.TermsOfCondition),
          getTerms(TERM.PrivacyPolicy),
        ]);

        const itens = data.map((term) => ({
          label: term.name?.replace("SCR B2B", ""),
          path: term.url,
        }));

        setMenuItens(itens);
      } catch (error) {
        //
      }
    };

    getTermMenu();
  }, []);

  return (
    <>
      <FooterWrapper>
        <Container>
          <Row centered>
            <Col sm={12} md={6} lg={6}>
              <Image src={logoBari} alt="Bari" />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <MenuWrapper>
                <Menu items={menuItems} horizontal />
              </MenuWrapper>
            </Col>
          </Row>
        </Container>
        <Copyright>
          <Container>
            <Col sm={12} md={12} lg={12}>
              A Bari Promotora de Serviços de Apoio Ltda é uma sociedade
              limitada registrada sob o CNPJ/MF 32.683.621/0001-03. Possui sede
              na Avenida Sete de Setembro, nº 4.781, sobreloja 02, Batel,
              Curitiba/PR, CEP 80.240-000 e também mantém escritório na Rua
              Álvaro Anes, nº 46, conjunto 113, Pinheiros, São Paulo/SP, CEP
              05.416-000.
            </Col>
          </Container>
        </Copyright>
      </FooterWrapper>
    </>
  );
};

export default Footer;

import styled from 'styled-components';

const ContentModal = styled.div`
  position: relative;
  background-color: #101010;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: none;
  ${props =>
    props.active &&
    `
    display: flex;
  `}
`;

const Container = styled.div`
  max-width: 1160px;
  color: white;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

export { ContentModal, Container };

import React from 'react';

// ASSETS
import { BariLogo, IconWrong } from './svgs';

// COMPONENTS
import { sideButton } from '@bancobarigui/reactbook';
import ButtonWrapper from '../../../components/UI/ButtonWrapper';
import Modal from '../../../components/UI/Modal';

// STYLES
import { ButtonContainer, ContentModal, FooterModal, TextModal, TitleModal } from './styles';

const ModalWrong = ({ active, onClose }) => {

  return (
    <Modal active={active}>
      <ContentModal>
        <IconWrong />
        <TitleModal>Dados não conferem</TitleModal>
        <TextModal>
          Por favor retorne a página anterior e verifique os dados digitados,
          caso esse erro continue acontecendo entre em contato com o seu
          parceiro.
          <ButtonContainer>
            <ButtonWrapper
              fixedWidth
              onClick={onClose}
              sideIcon={sideButton.LEFT}
            >
              Voltar
            </ButtonWrapper>
          </ButtonContainer>
        </TextModal>
        <FooterModal>
          <BariLogo />
        </FooterModal>
      </ContentModal>
    </Modal>
  );
};

export default ModalWrong;

import React from 'react';
import { MenuItemStyled, MenuItemLink } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const MenuItem = ({ label, path, horizontal }) => {
  return (
    <MenuItemStyled horizontal={horizontal}>
      <MenuItemLink href={path} target="_noblank">
        {label}
      </MenuItemLink>
    </MenuItemStyled>
  );
};

export default MenuItem;

import React, { useEffect, useState } from "react";

// ASSETS
import { CookieIcon } from "./svgs";
import { getTerms } from "../../utils/getTerms";

// COMPONENTS
import { Alignments, Toast, ToastThemes, Sizes } from "@bancobarigui/reactbook";

// STYLES
import { Text, Icon, Link } from "./styles";
// CONSTANTS
import { TERM } from "../../constants/terms";

const CookieToastr = ({ onClick, allowCookies }) => {
  const [active, setActive] = useState(true);
  const [policyDocument, setPolicyDocument] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setActive(allowCookies);
    }, 300);
  }, [allowCookies]);

  useEffect(() => {
    const getPolicyTerm = async () => {
      const data = await getTerms(TERM.PrivacyPolicy);
      setPolicyDocument(data.url);
    };

    getPolicyTerm();
  }, []);

  return (
    <Toast
      align={Alignments.CENTER_BOTTOM}
      size={Sizes.BIG}
      actionText="Ok, entendi"
      actionToast={true}
      active={active}
      theme={ToastThemes.LIGHT}
      onClick={onClick}
    >
      <Icon>
        <CookieIcon />
      </Icon>
      <Text>
        Este site usa cookies e outras tecnologias que ajudam a personalizar o
        conteúdo de acordo com nossa
        <Link href={policyDocument} target="_blank">
          Política de Privacidade
        </Link>
        . Ao continuar navegando, você declara estar ciente dessas condições.
      </Text>
    </Toast>
  );
};

export default CookieToastr;

import styled, { css } from 'styled-components';
import media from 'styled-media-query';

// COMPONENTS
import { Col, Row } from '../../Grid';

export const ColWrapper = styled(Col)`
  ${media.between('medium', 'large') &&
  media.greaterThan('large')`
    ${({ paddingLeft }) =>
      paddingLeft && `padding-left: 40px; padding-right: 250px;`};
    ${({ paddingRight }) =>
      paddingRight && `padding-right: 40px; padding-left: 250px;`};
  `}
`;

export const RowWrapper = styled(Row)`
  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;  
    padding: 32px;
    ${({ revertSideInMobile }) =>
      revertSideInMobile &&
      css`
        display: flex;
        flex-direction: column-reverse;
      `}
  `}
`;

export const SectionWrapper = styled.div`
  display: flex;
  ${media.lessThan('medium')`
    padding: 74px 0 0;
  `}

  ${media.greaterThan('medium')`
    padding: 0px 0px;
  `}
`;

export const SectionTitle = styled.label`
  display: flex;
  align-items: center;
  font-family: 'Clan Web Pro Medium', sans-serif;
  font-size: 10px;
  line-height: 16px;
  color: #0090ff;
  > img {
    margin-right: 8px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 31px 0 24px 0;
`;

export const ImageSection = styled.img`
  width: 100%;
  height: auto;

  ${media.lessThan('medium')`
        padding-top: 15px;
  `};
`;

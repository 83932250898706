import React, { useCallback, useContext, useEffect } from 'react';
import UserContext from '../../../context/UserContext';
import { Container, Button } from './styles';
import Close from './svgs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-12-14
 */
const Alert = props => {
  const { message, labelBtn, className, ...rest } = props;
  const { closeAlert } = useContext(UserContext);

  const handleClick = useCallback(() => {
    closeAlert();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
      clearTimeout(timer);
    }, 4000);
  }, [message]);

  return (
    <Container className={className} {...rest}>
      {message}
      <Button onClick={handleClick}>
        {labelBtn}
        <Close />
      </Button>
    </Container>
  );
};

export default Alert;

export function getValidationErrors(err) {
  const validationErrors = {};

  err.inner.forEach(error => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
}

export const personTypeOptions = [
  { value: 'Pessoa Física', label: 'Pessoa Física' },
  { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
];

export const actingFieldOptions = [
  { value: 'Assessoria Imobiliária', label: 'Assessoria Imobiliária' },
  {
    value: 'Assessoria/Consultoria Financeira',
    label: 'Assessoria/Consultoria Financeira',
  },
  { value: 'Correspondente bancário', label: 'Correspondente bancário' },
  { value: 'Seguros', label: 'Seguros' },
  { value: 'Fintech/Bancos', label: 'Fintech/Bancos' },
  { value: 'Investimentos', label: 'Investimentos' },
  { value: 'Imobiliária/Construtora', label: 'Imobiliária/Construtora' },
  { value: 'Franquias', label: 'Franquias' },
  { value: 'Contador', label: 'Contador' },
  { value: 'Varejo', label: 'Varejo' },
  { value: 'Serviços', label: 'Serviços' },
  { value: 'Distribuição', label: 'Distribuição' },
  { value: 'Indústria', label: 'Indústria' },
  {
    value: 'Turismo/Agência Intercâmbio',
    label: 'Turismo/Agência Intercâmbio',
  },
  { value: 'Saúde', label: 'Saúde' },
  { value: 'BPO', label: 'BPO' },
  { value: 'Software', label: 'Software' },
  { value: 'RH', label: 'RH' },
  { value: 'Outros', label: 'Outros' },
];

export const actingTypeOptions = [
  
  { id: 'Pessoa Jurídica', label: 'Pessoa Jurídica' },
  {
    id: 'MEI',
    label: 'Microempreendedor Individual (MEI)',
  },
];

export const ufOptions = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AM', label: 'AM' },
  { value: 'AP', label: 'AP' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MG', label: 'MG' },
  { value: 'MS', label: 'MS' },
  { value: 'MT', label: 'MT' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'PR', label: 'PR' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'RS', label: 'RS' },
  { value: 'SC', label: 'SC' },
  { value: 'SE', label: 'SE' },
  { value: 'SP', label: 'SP' },
  { value: 'TO', label: 'TO' },
];

export const actingTimeOptions = [
  { id: 'Até 1 ano', label: 'Até 1 ano' },
  { id: 'De 1 á 5 anos', label: 'De 1 á 5 anos' },
  { id: 'Mais de 5 anos', label: 'Mais de 5 anos' },
];

export const clientProfileOptions = [
  { value: 'Autônomo', label: 'Autônomo', id: 'Autônomo' },
  {
    value: 'Aposentado / Pensionista',
    label: 'Aposentado / Pensionista',
    id: 'Aposentado / Pensionista',
  },
  { value: 'CLT', label: 'CLT', id: 'CLT' },
  { value: 'Investidor', label: 'Investidor', id: 'Investidor' },
  { value: 'Empresário', label: 'Empresário', id: 'Empresário' },
  {
    value: 'Funcionários Públicos',
    label: 'Funcionários Públicos',
    id: 'Funcionários Públicos',
  },
];

export const clientFontOptions = [
  { value: 'Eventos', label: 'Eventos', id: 'Eventos' },
  {
    value: 'Marketing Performance',
    label: 'Marketing Performance',
    id: 'Marketing Performance',
  },
  { value: 'Networking', label: 'Networking', id: 'Networking' },
  { value: 'Online', label: 'Online', id: 'Online' },
  {
    value: 'Prospecção Ativa',
    label: 'Prospecção Ativa',
    id: 'Prospecção Ativa',
  },
];

export const howFindUsOptions = [
  { id: 'Instagram', label: 'Instagram' },
  { id: 'Facebook', label: 'Facebook' },
  { id: 'Google', label: 'Google' },
  { id: 'Linkedin', label: 'Linkedin' },
  { id: 'Indicação', label: 'Indicação' },
  { id: 'Outros', label: 'Outros' },
];

export const whyBeOurPartnerOptions = [
  {
    id: 'Tenho clientes com necessidades de crédito',
    label: 'Tenho clientes com necessidades de crédito',
  },
  {
    id: 'Estou procurando uma nova fonte de renda',
    label: 'Estou procurando uma nova fonte de renda',
  },
  {
    id: 'Quero aumentar o meu catálogo de soluções financeiras',
    label: 'Quero aumentar o meu catálogo de soluções financeiras',
  },
];

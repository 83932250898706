import styled from 'styled-components';
import InputMask from 'react-input-mask';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const StyledMaskedInput = styled(InputMask)`
  ${props =>
    props.readOnly &&
    `
        background: #F6F6F6;
    `};
  ${props =>
    props.hasError &&
    `
        border-color: #F83D44;
    `}
`;

const Hint = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: 'Clan Web Pro News', sans-serif;
  font-size: 12px;
  line-height: 22px;
  color: #8e8e8e;
`;

export { StyledMaskedInput, Hint };

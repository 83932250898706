import React from 'react';
import Image from '../Image';
import { Content, Icon } from './style';
import arrowIcon from '../../../static/images/scroll-icon.svg';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const ScrollToBottom = props => {
  const { onClick } = props;

  return (
    <Content>
      <Icon onClick={onClick}>
        <Image src={arrowIcon} alt="Arrow icon" />
      </Icon>
    </Content>
  );
};

export default ScrollToBottom;

import styled from 'styled-components';
import Select from 'react-select';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const StyledSelect = styled(Select)`
  .react-select__control {
    border-radius: 8px;
    > .react-select__value-container {
      padding: 16px;
      font-family: 'Clan Web Pro News', sans-serif;
      font-size: 14px;
      line-height: 24px;
      > .react-select__placeholder {
        margin-left: 5px;
        color: #8e8e8e;
      }
    }
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__indicator {
      padding-right: 12px;
      svg {
        color: #101010;
      }
    }
  }
  ${props =>
    props.hasError &&
    `
      > .react-select__control {
        border-color: #F83D44;
      }
    `}
`;

export default StyledSelect;

import React from 'react';
import { Container, Row, Col } from '../../../components/Grid';
import { AboutWrapper, Text } from './styles';

const AboutSection = () => {
  return (
    <AboutWrapper>
      <Container>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Text>
              A Bari Promotora de Serviços de Apoio Ltda atua como
              correspondente bancário, em conformidade com a Resolução CMN nº
              3.954, de 24/02/2011, sendo credenciada a oferecer produtos de
              crédito operados pelo Banco Bari de Investimentos e Financiamentos
              (CNPJ 00.556.603/0001-74) e pela Bari Companhia Hipotecária (CNPJ:
              14.511.781/0001-93). A Bari Promotora de Serviços de Apoio Ltda é
              uma sociedade limitada, registrada sob o CNPJ nº
              32.683.621/0001-03, com sede na Avenida Sete de Setembro, nº
              4.781, 2º andar, Água Verde, Curitiba/PR, CEP 80.250-205, também
              mantendo escritório na Rua Álvaro Anes, nº 46, conjunto 113,
              Pinheiros, São Paulo/SP, CEP 05.416-000.
            </Text>
            <Text>
              O cliente autoriza que, caso o(s) imóvel(is) que se deseja colocar
              em garantia junto ao BANCO BARI esteja alienado ou hipotecado a
              outra Instituição ou pessoa física, o Banco Bari consulte o saldo
              devedor atualizado junto à outra instituição, sem que isso enseje
              qualquer obrigação por parte do CLIENTE junto ao BANCO BARI ou do
              BANCO BARI junto ao CLIENTE.
            </Text>
          </Col>
        </Row>
      </Container>
    </AboutWrapper>
  );
};

export default AboutSection;

import React from 'react';
import Modal from '../../../components/UI/Modal';
import { ContentModal, TitleModal, TextModal } from './styles';
import { SuccessApply } from './svgs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-27
 */
const ModalFeedback = ({ active }) => {
  return (
    <Modal active={active}>
      <ContentModal>
        <SuccessApply />
        <TitleModal>Obrigado!</TitleModal>
        <TextModal>
          Recebemos suas informações e, em breve, nosso parceiro entrará em
          contato informando os próximos passos de sua proposta de crédito.
        </TextModal>
      </ContentModal>
    </Modal>
  );
};

export default ModalFeedback;

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const formSteps = [
  {
    title: "Confirmar as informações.",
    content: "Confira os dados que você informou para o nosso parceiro.",
  },
  {
    title: "Validar sua identidade.",
    content: "Digite o código recebido via SMS e confirme a sua identidade.",
  },
  {
    title: "Conceder autorização.",
    content:
      "Autorizar a análise no Sistema de Informações de Créditos do Banco Central (SCR).",
  },
];

const creditPurposes = [
  {
    label: "Adquirir Imóvel",
    value: 1,
  },
  {
    label: "Quitar Dívidas",
    value: 2,
  },
  {
    label: "Portabilidade",
    value: 3,
  },
  {
    label: "Viajar / Lazer",
    value: 4,
  },
  {
    label: "Construção ou Reforma",
    value: 5,
  },
  {
    label: "Investimento",
    value: 6,
  },
  {
    label: "Abrir minha empresa / franquia",
    value: 7,
  },
  {
    label: "Capital de giro para minha empresa",
    value: 8,
  },
  {
    label: "Aquisição de bens",
    value: 9,
  },
  {
    label: "Outros",
    value: 10,
  },
];

export { creditPurposes, formSteps };

import styled from 'styled-components';

export const ContentWrapper = styled.div`
  @media screen and (min-width: 1081px) {
    min-height: 660px;
    display: flex;
  }
`;

export const CarouselItems = styled.div`
  display: flex;
`;

export const CarouselContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ContentCarouselImage = styled.div`
  display: flex;
  width: auto;
  justify-content: flex-end;

  @media screen and (max-width: 1080px) {
    margin-bottom: 44px;
    width: 100%;
    justify-content: flex-start;
    padding-left: 35px;
    padding-bottom: 35px;
    padding-top: 25px;
  }
`;

export const GradeImage = styled.img`
  z-index: 3;
  width: 120px;

  @media screen and (max-width: 1080px) {
    width: 88px;
  }
`;

export const RectangleImage = styled.img`
  z-index: 5;
  bottom: 23px;
  right: 13px;
  height: 112px;
  width: 112px;

  @media screen and (max-width: 1080px) {
    height: 61px;
    width: 61px;
    bottom: 30px;
    right: 29px;
  }
`;

export const RectangleSmall = styled.img`
  width: 27px;
  height: 27px;
  top: 60px;

  @media screen and (max-width: 1080px) {
    height: 16px;
    width: 16px;
    top: 44px;
  }
`;

export const RectangleWhite = styled.img`
  width: 52px;
  height: 52px;
  bottom: -30px;
  left: -9px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const IconsRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  img {
    position: absolute;
  }
`;

export const ImageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  transform: rotate(45deg);
  overflow: hidden;
  width: 300px;
  height: 300px;
  border-radius: 64px;
  z-index: 4;
  margin-right: 64px;
  margin-top: 35px;

  img {
    width: 124%;
    transform: rotate(-45deg);
    position: absolute;
    top: -36px;
    bottom: 0px;
    left: -36px;
  }

  @media screen and (max-width: 1080px) {
    width: 200px;
    height: 200px;
    border-radius: 36px;
    margin-top: 0;

    img {
      width: 127%;
      top: -26px;
      left: -26px;
    }
  }
`;

export const Title = styled.h2`
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 60px;
  margin-bottom: 37px;

  @media screen and (max-width: 1080px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Description = styled.h4`
  letter-spacing: 0px;
  color: #646464;
  opacity: 1;
  text-align: left;
  font-size: 22px;
  line-height: 38px;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  min-height: 231px;

  @media screen and (max-width: 1080px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Footer = styled.p`
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  margin-top: 33px;

  @media screen and (max-width: 1080px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Texts = styled.div`
  flex-direction: column;
  margin-right: 73px;
  width: 50%;

  @media screen and (max-width: 1080px) {
    margin: 0;
    width: 100%;
  }
`;

export const ButtonContent = styled.div`
  margin-top: 63px;
  @media screen and (max-width: 1080px) {
    justify-content: space-between;
    display: flex;
  }

  button {
    border: none;
    background: #ffffff;

    img {
      height: 33px;
    }

    & + button {
      margin-left: 108px;
    }
  }
`;

import styled from 'styled-components';

export const Advantages = styled.div`
  display: flex;
  flex-direction: column;

  & div:last-child {
    margin-bottom: 0;
  }
`;

export const AdvantagePoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;

  img {
    margin-right: 24px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1080px) {
    font-size: 16px;
    line-height: 20px;
    img {
      margin-right: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #323232;
  color: #fff;
  width: 100%;
  padding-bottom: 160px;
  padding-top: 160px;

  button {
    height: 55px;
    max-width: 308px;
    border-radius: 8px;
    width: 100%;
    max-width: 308px;
  }

  @media screen and (max-width: 1080px) {
    padding-bottom: 20px;

    button {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;

  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    width: 82%;
  }
`;

export const ContentImage = styled.img`
  margin-bottom: 20px;

  @media screen and (min-width: 1081px) {
    margin-bottom: 25px;
  }

  @media screen and (max-width: 768px) {
    height: 60px;
    margin-bottom: 32px;
  }
`;

export const ContentItem = styled.div`
  width: 43%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 130px;

  img {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1080px) {
    width: 100%;
    margin-bottom: 80px;
  }
`;

export const ContentTile = styled.h3`
  margin-bottom: 32px;
  line-height: 36px;

  @media screen and (max-width: 1080px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  margin-bottom: 120px;
  line-height: 42px;

  @media screen and (max-width: 1080px) {
    width: 82%;
    font-size: 32px;
    margin-bottom: 60px;
  }
`;

import styled from 'styled-components';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-11
 */
const ErrorMessage = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: 'Clan Web Pro News', sans-serif;
  font-size: 12px;
  line-height: 22px;
  color: #f83d44;
`;

export default ErrorMessage;

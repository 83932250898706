import { useField } from "@unform/core";
import React, { useCallback, useEffect, useRef, useState } from "react";

// COMPONENTS
import { Checkbox, CheckBoxType, InputType } from "@bancobarigui/reactbook";

// STYLES
import { Container, Error } from "./styles";

const CheckboxList = ({ name, options }) => {
  const inputRefs = useRef([]);
  const [items, setItems] = useState(options);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField, inputRefs]);

  const handleChange = useCallback(
    (e, id) => {
      const target = e.target;

      const oldItems = [...items];

      const newItems = oldItems?.map((item) => {
        if (item.id === id) {
          item["status"] = target.checked;
        }
        return item;
      });

      setItems(newItems);
    },
    [items]
  );

  return (
    <Container>
      {items.map((option, index) => (
        <Checkbox
          checked={option.status}
          inputType={InputType.CHECKBOX}
          key={option.id}
          label={option.label}
          name={option.label}
          onChange={(event) => handleChange(event, option.id)}
          ref={(ref) => {
            inputRefs.current[index] = ref;
          }}
          type={CheckBoxType.DEFAULT}
          value={option.value}
        />
      ))}
      {error && <Error title={error}>{error}</Error>}
    </Container>
  );
};
export default CheckboxList;

import React, { useEffect, useState } from 'react';

// ASSETS
import Grade from '../../../assets/grade.svg';
import Next from '../../../assets/next.svg';
import Preview from '../../../assets/preview.svg';
import RectangleMedium from '../../../assets/rectangle-medium.svg';
import RectangleSmallImage from '../../../assets/rectangle-medium.svg';
import RectangleWhiteImage from '../../../assets/rectangle-white.svg';

// STYLES
import {
  ButtonContent,
  CarouselContainer,
  CarouselItems,
  ContentCarouselImage,
  ContentWrapper,
  Description,
  Footer,
  GradeImage,
  IconsRight,
  ImageContent,
  ImageWrapper,
  RectangleImage,
  RectangleSmall,
  RectangleWhite,
  Texts,
  Title,
} from './styles';

const Carousel = ({ items }) => {
  const [activeItem, setActiveItem] = useState(0);
  const [currentItem, setCurrentItem] = useState(items[0]);

  useEffect(() => {
    setCurrentItem(items[activeItem]);
  }, [activeItem]);

  const onNextClick = () => {
    setActiveItem(activeItem < items.length - 1 ? activeItem + 1 : 0);
  };

  const onPreviousClick = () => {
    setActiveItem(activeItem > 0 ? activeItem - 1 : items.length - 1);
  };

  return (
    <CarouselItems>
      <CarouselContainer>
        <ImageContent>
          <Texts>
            <ContentWrapper>
              <div>
                <Title>{currentItem.title}</Title>
                <Description>{currentItem.content}</Description>
                <Footer>{currentItem.footer}</Footer>
              </div>
            </ContentWrapper>
            <ButtonContent>
              <button onClick={onNextClick}>
                <img src={Preview} alt="Anterior" />
              </button>
              <button onClick={onPreviousClick}>
                <img src={Next} alt="Próximo" />
              </button>
            </ButtonContent>
          </Texts>
          <ContentCarouselImage>
            <IconsRight>
              <RectangleSmall src={RectangleSmallImage} alt="Rectangle Small" />
              <RectangleWhite
                src={RectangleWhiteImage}
                alt="Rectangle White "
              />
            </IconsRight>
            <ImageWrapper>
              <img src={currentItem.img} alt={currentItem.alt} />
            </ImageWrapper>
            <IconsRight>
              <GradeImage src={Grade} alt="Grade" />
              <RectangleImage src={RectangleMedium} alt="Rectangle" />
            </IconsRight>
          </ContentCarouselImage>
        </ImageContent>
      </CarouselContainer>
    </CarouselItems>
  );
};

export default Carousel;

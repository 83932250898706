import React from 'react';
import { Link } from 'react-router-dom';
import { StyledStep, StepTitle, StepContent } from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const Step = ({ title, content, link }) => {
  return (
    <StyledStep>
      <StepTitle>{title}</StepTitle>
      <StepContent>
        {content}
        {link != null && <Link to="/">Saiba mais</Link>}
      </StepContent>
    </StyledStep>
  );
};

export default Step;

import styled from 'styled-components';
import media from 'styled-media-query';
import iconCheck from '../../../static/images/check-icon-round.svg';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const StyledSteps = styled.div`
  ${media.lessThan('medium')`
        margin-top: 24px;
        margin-bottom: 56px;
    `}
  ${media.greaterThan('medium')`
        margin-top: 56px;
        margin-bottom: 116px;
    `}
`;

const StyledStep = styled.div`
  position: relative;
  display: block;
  ${media.lessThan('medium')`
        padding-left: 40px;
    `}
  ${media.greaterThan('medium')`
        padding: 0 56px;
    `}        
    margin-bottom: 32px;
  max-width: 100%;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  &:before {
    content: url(${iconCheck});
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const StepTitle = styled.h5`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Clan Web Pro Medium', sans-serif;
  color: #101010;
`;

const StepContent = styled.p`
  font-size: 14px;
  color: #646464;
  > a {
    margin-left: 4px;
    color: #4aaef2;
    text-decoration: none;
  }
`;

export { StyledSteps, StyledStep, StepTitle, StepContent };

import React, { useState } from 'react';

// ASSETS
import arrowRight from '../../../../assets/arrow-right-line.svg';
import heroImage from '../../../../assets/video-preview.png';
import graphicIcon from '../../../../assets/graphic-icon.svg';

// COMPONENTS
import { Button, sideButton } from '@bancobarigui/reactbook';

// STYLES
import { Container, WrapperImage } from './styles';
import {
  ButtonWrapper,
  ContentSection,
  Description,
  IconContainer,
  SectionSimple,
  SectionWithImage,
  SubTitle,
  Title,
} from '../../styles';

const VideoSection = ({ executeScroll }) => {
  const [videoClicked, setVideoClicked] = useState(false);
  return (
    <Container>
      <ContentSection>
        <SectionWithImage>
          <WrapperImage>
            {videoClicked ? (
              <iframe
                title="video-simulator"
                src="https://www.youtube.com/embed/CkP400-XYjU/?autoplay=1"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            ) : (
              <img
                src={heroImage}
                alt="Click to see the video"
                onClick={() => setVideoClicked(true)}
              />
            )}
          </WrapperImage>
        </SectionWithImage>
        <SectionSimple>
          <IconContainer>
            <img src={graphicIcon} alt="Ícone de gráfico" />
          </IconContainer>
          <>
            <Title>
              Tudo que você precisa para realizar suas vendas em um só lugar
            </Title>
            <SubTitle>Autonomia, controle e resultados.</SubTitle>
            <Description>
              Portal de Parceiros Bari auxilia você desde a simulação do crédito
              até a assinatura final de seu cliente. E, em breve, trará
              relatórios de produtividade, área de capacitação e auxilio às
              vendas com materiais e campanhas de incentivo.
            </Description>

            <ButtonWrapper>
              <Button
                fixedWidth
                black
                sideIcon={sideButton.RIGHT}
                icon={() => <img src={arrowRight} alt="Arrow right" />}
                onClick={executeScroll}
              >
                Seja nosso parceiro
              </Button>
            </ButtonWrapper>
          </>
        </SectionSimple>
      </ContentSection>
    </Container>
  );
};

export default VideoSection;

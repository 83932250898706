import axios from "axios";
import { basePath } from "../constants/api";

export default class UserResource {
  static sendToken(id, data) {
    return axios
      .post(`${basePath}/realestate/v1/signature/${id}/sendtoken`, data)
      .then((response) => {
        return response;
      });
  }

  static tokenValidate(id, data) {
    return axios
      .post(`${basePath}/realestate/v1/signature/${id}/tokenvalidate`, data)
      .then((response) => {
        return response;
      });
  }
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 68%;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 160px;

  @media screen and (max-width: 1500px) {
    width: 90%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 47.5%;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const KnowAdvantages = styled.p`
  font-size: 48px;
  font-weight: 400;
  margin-bottom: 80px;
  line-height: 48px;

  @media screen and (max-width: 1080px) {
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    strong {
      font-weight: 700;
    }
  }
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    justify-content: space-between;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  padding: 32px 24px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 48px;
  line-height: 40px;

  @media screen and (max-width: 568px) {
    line-height: 30px;
    font-size: 20px;
    font-weight: 600;
  }
`;

import React, { useState, createContext } from "react";

const UserContext = createContext();

const UserProvider = ({ parameters, linkValid, linkExpired, children }) => {
  const [alert, setAlert] = useState({
    active: false,
    message: "",
    labelBtn: "",
  });
  const [userParams, setUserParams] = useState({
    document: "",
    creditFinality: "",
    proponentName: "",
    phoneNumber: "",
  });

  const onChangeValues = (values) => {
    setUserParams({
      ...parameters,
      ...values,
    });
  };

  const openAlert = (message, labelBtn) => {
    setAlert({
      active: true,
      message,
      labelBtn,
    });
  };

  const closeAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      active: false,
    }));
  };

  return (
    <UserContext.Provider
      value={{
        parameters,
        linkValid,
        linkExpired,
        onChangeValues,
        openAlert,
        closeAlert,
        userParams,
        alert,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
export default UserContext;

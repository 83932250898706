import React from "react";

// ASSETS
import arrowRight from "../../../../assets/arrow-right-line.svg";
import checkIcon from "../../../../assets/check-icon.svg";

// COMPONENTS
import { Button, sideButton } from "@bancobarigui/reactbook";

//CONSTANTS
import { PartnerAdvantagesList } from "../../../../constants/advantages";

// STYLES
import {
  Advantages,
  AdvantagePoint,
  Container,
  Content,
  ContentItem,
  ContentTile,
  Title,
  ContentImage,
} from "./styles";
import { ButtonWrapper } from "../../styles";

const PartnerAdvantage = ({ executeScroll }) => {
  const renderAdvantages = (advantages, key) => {
    return advantages.map((advantage, index) => {
      return (
        <AdvantagePoint key={`point-${key}-${index}`}>
          <img src={checkIcon} alt="Ícone de check" />
          <span>{advantage}</span>
        </AdvantagePoint>
      );
    });
  };

  const renderContent = () => {
    return PartnerAdvantagesList.map((advantage, index) => {
      return (
        <ContentItem key={`advantage-${index}`}>
          <ContentImage src={advantage.icon} alt={`Ícone ${advantage.title}`} />
          <ContentTile>{advantage.title}</ContentTile>
          <Advantages>
            {renderAdvantages(advantage.advantages, index)}
          </Advantages>
        </ContentItem>
      );
    });
  };

  return (
    <Container>
      <Title>
        Vantagens de ser <span>Parceiro Bari</span>
      </Title>
      <Content>{renderContent()}</Content>

      <ButtonWrapper>
        <Button
          fixedWidth
          blue
          sideIcon={sideButton.RIGHT}
          icon={() => <img src={arrowRight} alt="Arrow right" />}
          onClick={executeScroll}
        >
          Seja nosso parceiro
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default PartnerAdvantage;

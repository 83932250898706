import React from "react";

// ASSETS
import manMoney from "../../../../assets/man-money.svg";
import housePennyIcon from "../../../../assets/house-penny-icon.svg";
import arrowRight from "../../../../assets/arrow-right-line.svg";

// COMPONENTS
import { Button, sideButton } from "@bancobarigui/reactbook";

// STYLES
import { Container, MainTitleContainer } from "./styles";
import {
  ButtonWrapper,
  ContentSection,
  Description,
  IconContainer,
  SectionSimple,
  SectionWithImage,
  SubTitle,
  Title,
} from "../../styles";

const ModernWay = ({ executeScroll }) => {
  return (
    <Container>
      <MainTitleContainer>
        <p>
          <strong>Seja nosso parceiro,</strong> aumente sua renda e nos ajude a
          oferecer os
          <strong> melhores produtos</strong> de Crédito e Financiamento
          Imobiliário.
        </p>
      </MainTitleContainer>

      <ContentSection>
        <SectionSimple>
          <IconContainer>
            <img src={housePennyIcon} alt="Ícone Casa e Moeda" />
          </IconContainer>
          <>
            <Title>
              Uma forma moderna do Bari ajudar você a vender Crédito
              Imobiliário.
            </Title>

            <SubTitle>Linha de Crédito com Garantia de Imóvel</SubTitle>
            <Description>
              Mais liberdade para o seu cliente utilizar o crédito. Dinheiro na
              conta e Baricard Imobiliário, primeiro cartão de crédito com
              garantia de imóvel do Brasil.
            </Description>
            <SubTitle>Barifin: financiamento imobiliário</SubTitle>
            <Description>
              Aquisição de imóvel com análise de crédito personalizada,
              utilização do FGTS e despesas de ITBI e registro diluídas no
              financiamento.
            </Description>
            <ButtonWrapper>
              <Button
                fixedWidth
                black
                sideIcon={sideButton.RIGHT}
                icon={() => <img src={arrowRight} alt="Arrow right" />}
                onClick={executeScroll}
              >
                Seja nosso parceiro
              </Button>
            </ButtonWrapper>
          </>
        </SectionSimple>
        <SectionWithImage>
          <img src={manMoney} alt="Imagem homem e dinheiro" />
        </SectionWithImage>
      </ContentSection>
    </Container>
  );
};

export default ModernWay;

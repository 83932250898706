import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 160px;

  button {
    max-width: 308px;
  }

  @media screen and (max-width: 768px) {
    button {
      display: none;
    }
  }
`;

export const WrapperImage = styled.div`
  img {
    display: flex;
    max-width: 100%;

    @media screen and (max-width: 1500px) {
      max-height: 370px;
      max-width: 100%;
    }

    @media screen and (max-width: 1080px) {
      padding-top: 0;
      margin-left: 0;
    }
  }

  img,
  iframe {
    border-radius: 10px;
    box-shadow: 0px 5px 50px #0000001f;
    object-fit: cover;
    width: 35vw;
    height: 40vh;

    @media screen and (max-width: 1400px) {
      width: 45vw;
      height: 45vh;
    }
    @media screen and (max-width: 1080px) {
      width: 75vw;
      height: 50vh;
      margin-bottom: 0;
      max-width: 82%;
      margin: auto;
    }
    @media screen and (max-width: 568px) {
      width: 75vw;
      height: 25vh;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 568px) {
    img {
      padding-top: 0;
    }
  }
`;

import React from 'react';

// ASSETS
import logoBari from '../../../assets/logo-bari.svg';

// STYLES
import { Container, DotLogo } from './styles';

const Logo = () => {
  return (
    <Container>
      <img src={logoBari} alt="Logo Bari" />
      <DotLogo />
    </Container>
  );
};

export default Logo;

import React, { useCallback, useEffect } from "react";
import Label from "../Label";
import ErrorMessage from "../ErrorMessage";
import FieldContainer from "../FieldContainer";
import StyledSelect from "./style";
import { useState } from "react";

const Select = (props) => {
  const {
    label,
    name,
    id,
    options,
    form,
    hint = null,
    error = null,
    onBlur,
    value,
  } = props;
  const [hasHint] = useState(hint !== null);
  const [hasError] = useState(error !== null);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: "100%",
      padding: "18px 0",
      margin: "8px 0",
      borderRadius: 8,
      border: "1px solid #F6F6F6",
      boxShadow: "0px 10px 30px 10px rgba(0, 0, 0, 0.07)",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "8px 24px",
      color: state.isFocused ? "#101010" : "#555555",
      fontFamily: state.isSelected
        ? "Clan Web Pro Medium"
        : "Clan Web Pro News",
      fontSize: 13,
      lineHeight: "24px",
      background: state.isFocused ? "#F6F6F6" : "none",
    }),
  };

  const handleChange = useCallback(({ value }) => {
    form.setFieldValue(name, value);
  }, []);

  console.log(value, hasError);

  return (
    <FieldContainer>
      <Label hasHint={hasHint} hasError={hasError}>
        {label}
      </Label>
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
      <StyledSelect
        name={name}
        id={id}
        styles={customStyles}
        classNamePrefix="react-select"
        placeholder="Selecione a finalidade do crédito"
        hasError={hasError}
        options={options}
        onChange={handleChange}
        onBlur={() => onBlur({ target: { name: "creditFinality" } })}
        value={value === "" ? "" : undefined}
      />
    </FieldContainer>
  );
};

export default Select;

import React, { forwardRef } from 'react';

// COMPONENTS
import Title from '../../Typography';
import Image from '../Image';

// STYLES
import {
  ColWrapper,
  ImageSection,
  RowWrapper,
  SectionTitle,
  SectionWrapper,
  TitleWrapper,
} from './styles';

const Section = forwardRef(
  (
    {
      imageSide = 'right',
      revertSideInMobile = false,
      sectionImage,
      sectionTitle,
      imagem,
      message,
      title,
      ...restProps
    },
    ref,
  ) => {
    const SectionImage = () => {
      return (
        <ColWrapper sm={12} md={6} lg={6}>
          {imagem && <ImageSection src={imagem} alt={title} />}
        </ColWrapper>
      );
    };

    const SectionText = () => {
      return (
        <ColWrapper
          paddingLeft={imageSide === 'left'}
          paddingRight={imageSide === 'right'}
          sm={12}
          md={6}
          lg={6}
        >
          <SectionTitle>
            {sectionImage && <Image src={sectionImage} alt={sectionTitle} />}
            {sectionTitle}
          </SectionTitle>
          <TitleWrapper>
            <Title size={2}>{title}</Title>
          </TitleWrapper>
          <Title size={3}>{message}</Title>
        </ColWrapper>
      );
    };

    return (
      <SectionWrapper ref={ref} imageSide={imageSide} {...restProps}>
        <RowWrapper revertSideInMobile={revertSideInMobile}>
          {imageSide === 'left' ? <SectionImage /> : <SectionText />}
          {imageSide === 'right' ? <SectionImage /> : <SectionText />}
        </RowWrapper>
      </SectionWrapper>
    );
  },
);

export default Section;

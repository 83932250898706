import styled from 'styled-components';
import media from 'styled-media-query';
import bgScroll from '../../../static/images/scroll-bg.png';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const Content = styled.div`
  z-index: 1;
  position: relative;
  display: block;
  ${media.greaterThan('medium')`
        margin: 0 auto;
    `}
  width: 86px;
  height: 45px;
  background: url(${bgScroll});
  background-size: cover;
`;

const Icon = styled.a`
  position: relative;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background: #101010;
  border-radius: 50%;
  transition: background 0.65s;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #2e2e2e;
  }
`;

export { Content, Icon };

import React from "react";

// COMPONENTS
import { Button } from "@bancobarigui/reactbook";

// STYLES
import { ButtonArrows } from "./styles";

const ButtonWrapper = ({ children, sideIcon, onClick, ...restProps }) => {
  const Arrows = () => (
    <ButtonArrows sideIcon={sideIcon}>
      <span />
      <span />
      <span />
      <span />
      <span />
    </ButtonArrows>
  );

  return (
    <Button onClick={onClick} sideIcon={sideIcon} icon={Arrows} {...restProps}>
      {children}
    </Button>
  );
};

export default ButtonWrapper;

import CarouselFinanzero from "../assets/partnes/carousel/finanzero.png";
import CarouselRudCred from "../assets/partnes/carousel/rudcred.png";
import CarouselRw from "../assets/partnes/carousel/rw-solucao.png";

export const homeCarousel = [
  {
    img: `${CarouselRw}`,
    title: "É uma satisfação ter a Bari como parceira! Recomendamos fortemente!",
    content:
      "O sólido relacionamento com a Bari nos possibilita entregar credibilidade e serviço de excelência ao mercado. É uma satisfação ter a Bari como parceira! Recomendamos fortemente!",
    footer: "Reginaldo Ferroni - Sócio diretor RW Solução em Crédito.",
    alt: "RW",
  },
  {
    img: `${CarouselRudCred}`,
    title: "Profissionalismo, competência e transparência sempre foi a forma como fomos tratados.",
    content:
      "A Bari é uma grande parceira desde o início. Venho acompanhando seu crescimento a 3 anos e o profissionalismo, competência e transparência sempre foi a forma como fomos tratados. É uma excelente empresa com profissionais excelentes.",
    footer: "Andreus Fernandes Hudinik - Sócio diretor RudCred.",
    alt: "RudCred",
  },
  {
    img: `${CarouselFinanzero}`,
    title: "Sempre tivemos um super suporte e sempre fomos muito bem atendidos.",
    content:
      "Eu e o meu time tivemos a grande experiência de trabalhar com o Banco Bari, onde sempre tivemos um super suporte e sempre fomos muito bem atendidos.",
    footer: "Vitório Teles de Freitas - Assistente CS FinanZero.",
    alt: "FinanZero",
  },
];

import React from 'react';
import { createGlobalStyle } from 'styled-components';

// COMPONENTS
import Header from './Header';
import Footer from './Footer';

// STYLE
import style from '../../shared/style.css';

const GlobalStyle = createGlobalStyle(style);

const withLayout = ({ header, footer }) => Page => () => {
  return (
    <>
      <GlobalStyle />
      {header && <Header />}
      <Page />
      {footer && <Footer />}
    </>
  );
};

export default withLayout;

import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import { Select } from '@bancobarigui/reactbook'

// STYLES
import { Container, Error } from './styles'

const SelectContainer = ({ id, name, label, ...rest }) => {
  const selectRef = useRef(null)

  const { fieldName, registerField, error } = useField(name)

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#f6f6f6',
      border: 'none',
      borderBottom: error ? '2px solid #DB3939' : '2px solid #000',
      borderRadius: 0,
    }),
    valueContainer: () => ({
      padding: '2px 0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: () => ({
      color: '#000',
    }),
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.selectValue) {
            return []
          }
          return ref.state.selectValue.map((option) => option.value)
        }
        return ref.state.selectValue[0]?.value
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <Container>
      <Select
        id={id}
        ref={selectRef}
        label={label}
        customStyles={customStyles}
        {...rest}
      />
      {error && <Error title={error}>{error}</Error>}
    </Container>
  )
}

export default SelectContainer

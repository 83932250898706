import React from 'react';

// COMPONENTS
import Logo from '../Logo';

// COMPONENTS
import { Button } from '@bancobarigui/reactbook';

// STYLES
import { Container } from './styles';

const HeaderBlack = ({ onClick }) => {
  return (
    <Container>
      <Logo />
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_URL_PORTAL}
        >
          Acessar o Portal
        </a>
        <Button blue onClick={onClick}>
          Seja nosso parceiro
        </Button>
      </div>
    </Container>
  );
};

export default HeaderBlack;

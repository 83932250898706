import styled from 'styled-components';

export const HiddenDiv = styled.div`
  display: none;
`;

export const ButtonWrapper = styled.div`
  width: 308px;
  
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
  height: auto;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const ContainerCarousel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  padding-top: 160px;
  padding-bottom: 222px;
  width: 100%;
`;

export const ContentCarousel = styled.div`
  width: 68%;

  @media screen and (max-width: 1080px) {
    width: 82%;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 68%;
  align-items: center;

  @media screen and (max-width: 1500px) {
    width: 90%;
  }

  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    align-items: center;
    width: 99%;
  }
`;

export const IconContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  width: 84px;
  margin-bottom: 40px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const PartnersImg = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 180px;
  @media screen and (max-width: 1080px) {
    margin-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    img {
      min-width: 150px !important;
    }
  }

  img {
    width: 16%;
    & + img {
      padding: 16px 0;
    }
  }
`;

export const StickyButton = styled.div`
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  border-top: 1px solid #f6f6f6;
  padding: 16px 0;
  background-color: #fff;
  margin-bottom: -89px;
  align-items: center;

  button {
    width: 82%;
    height: 56px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 569px) {
    display: none;
  }
`;

export const SectionSimple = styled.div`
  display: flex;
  width: 39%;
  flex-direction: column;
  font-weight: 600;

  @media screen and (max-width: 1080px) {
    width: 82%;
  }
`;

export const SectionWithImage = styled.div`
  display: flex;
  width: 50%;

  @media screen and (max-width: 1080px) {
    width: 99%;
    justify-content: center;
  }

  img {
    display: flex;
    max-width: 100%;
    width: auto;

    @media screen and (max-width: 1080px) {
      padding-top: 0;
      margin-left: 0;
      margin-bottom: 55px;
    }

    @media screen and (max-width: 568px) {
      padding-top: 0;
    }
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  margin-bottom: 32px;
  line-height: 48px;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const SectionTitle = styled.h2`
  letter-spacing: 0px;
  color: #000000;
  font-family: 'Nunito', sans-serif;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  margin-bottom: 133px;
  padding: 0 95px;

  @media screen and (max-width: 1080px) {
    padding: 0;
    font-size: 32px;
    line-height: 42px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 24px;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 24px;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  color: #555555;
  margin-bottom: 48px;
  line-height: 32px;

  @media screen and (max-width: 768px) {
    font-weight: 400;
    line-height: 30px;
  }
`;

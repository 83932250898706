import React from 'react';
import { Container, Row, Col } from '../../../components/Grid';
import Title from '../../../components/Typography';
import ScrollToBottom from '../../../components/UI/ScrollToBottom';

// STYLES
import { IntroWrapper, BackgroundImage } from './styles';

const IntroSection = props => {
  return (
    <IntroWrapper>
      <Container>
        <Row noPaddingTop={true}>
          <Col sm={0} md={6} lg={6} />
          <Col sm={12} md={6} lg={6}>
            <Title size={1}>
              <span>Parceiro</span> confira as novidades que preparamos para
              você:
            </Title>
            <br />
            <Title size={4} bold>
              Um Portal de Parceiros cada vez mais completo.
            </Title>
          </Col>
        </Row>
      </Container>
      <BackgroundImage>
        <ScrollToBottom {...props} />
      </BackgroundImage>
    </IntroWrapper>
  );
};

export default IntroSection;

import React from 'react';

// STYLES
import { Container, Title, Description } from './styles';

const Card = ({ description, title, type = 'default' }) => {
  return (
    <Container type={type}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default Card;

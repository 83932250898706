import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 160px;

  button {
    max-width: 308px;
  }

  @media screen and (max-width: 768px) {
    button {
      display: none;
    }
  }
`;


export const MainTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  margin-bottom: 160px;
  width: 68%;

  @media screen and (max-width: 1500px) {
    width: 90%;
  }

  @media screen and (max-width: 1080px) {
    width: 82%;
    justify-content: center;

    strong {
      font-weight: 700;
    }
  }

  p {
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    text-align: center;

    @media screen and (max-width: 768px) {
      text-align: left;
    }

    @media screen and (max-width: 568px) {
      font-size: 32px;
      text-align: left;
      font-weight: 600;
      line-height: 48px;
    }
  }
`;


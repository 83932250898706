import React from 'react';
import { Container, Row, Col } from '../../../components/Grid';
import Title from '../../../components/Typography';
import ScrollToBottom from '../../../components/UI/ScrollToBottom';
import { IntroWrapper, BackgroundImage } from './styles';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const IntroSection = props => {
  return (
    <IntroWrapper>
      <Container>
        <Row>
          <Col sm={0} md={6} lg={6} />
          <Col sm={12} md={6} lg={6}>
            <Title size={1}>
              <span>Estamos prontos para dar continuidade</span> a sua proposta
              de crédito.
            </Title>
            <br />
            <Title size={4} bold>
              Mas, antes, precisamos de sua atenção.
            </Title>
          </Col>
        </Row>
      </Container>
      <BackgroundImage>
        <ScrollToBottom {...props} />
      </BackgroundImage>
    </IntroWrapper>
  );
};

export default IntroSection;

import React, { useState, useEffect } from 'react';

// ASSETS
import heroImage from '../../../../assets/hero-image.png';
import heroImage2x from '../../../../assets/hero-image-2x.png';
import mobileHeroImage from '../../../../assets/mobile-hero-image.png';

// STYLES
import { Container } from './styles';

const HeroImage = () => {
  const [width, setWidth] = useState(document.body.clientWidth);

  const updateWidthAndHeight = () => {
    setWidth(document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return (
    <Container>
      <img
        id="hero-image"
        src={
          width > 490
            ? width > 1920
              ? heroImage2x
              : heroImage
            : mobileHeroImage
        }
        alt="Hero"
      />
    </Container>
  );
};

export default HeroImage;

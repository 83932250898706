import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #323232;
  height: 612px;
  @media screen and (max-width: 768px) {
    margin-top: -3px !important;
  }

  @media screen and (max-width: 600px) {
    height: 512px !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 864px;
    padding-top: 149px;

    @media screen and (max-width: 1024px) {
      max-width: 82% !important;
      padding-top: 70px !important;
    }

    button {
      max-width: 311px;
      height: 60px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 568px) {
        max-width: 100% !important;
        width: 100%;
      }
    }
  }
`;

export const TitleStyled = styled.p`
  font-size: 48px;
  color: #fff;
  font-weight: 400;
  line-height: 74px;

  @media screen and (max-width: 600px) {
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    strong {
      font-weight: 700;
    }
  }
`;

export const SubTitle = styled.p`
  font-size: 28px;
  color: #fff;
  opacity: 70%;
  font-weight: 400;
  padding-top: 32px;
  margin-bottom: 48px;
  line-height: 40px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheusvieirasto@gmail.com>
 * @since 2020-11-09
 */
const H1 = styled.h1`
  font-family: 'Clan Web Pro Book', sans-serif;
  font-weight: 500;
  ${media.lessThan('medium')`
    font-size: 30px;
    line-height: 40px;
  `}
  ${media.greaterThan('medium')`
    font-size: 42px;
    line-height: 56px;
  `}  
  color: #101010;
  > span {
    color: #646464;
  }
`;

const H2 = styled.h2`
  font-family: 'Clan Web Pro News', sans-serif;
  font-weight: 500;
  ${media.lessThan('medium')`
    font-size: 22px;
    line-height: 32px;
  `}
  ${media.greaterThan('medium')`
    font-size: 28px;
    line-height: 40px;
  `}    
  ${props =>
    !props.light &&
    `
    color: #101010;
  `};
  ${props =>
    props.light &&
    `
    color: #FFFFFF;
  `};
`;

const H3 = styled.h3`
  font-family: 'Clan Web Pro News', sans-serif;
  font-weight: 500;
  ${media.lessThan('medium')`
      font-size: 14px;
      line-height: 24px;
  `}
  ${media.greaterThan('medium')`
      font-size: 16px;
      line-height: 32px;
  `}
  letter-spacing: 0.01em;

  ${props =>
    !props.light &&
    `
    color: #8e8e8e;
  `};

  ${props =>
    props.light &&
    `
    color: #FFFFFF;
  `};

  ${props =>
    props.center &&
    `
    text-align: center;
  `};
`;

const H4 = styled.h4`
  font-size: 17px;
  line-height: 24px;
  color: #101010;
  ${props =>
    props.bold &&
    `
    font-family: "Clan Web Pro Medium", sans-serif;
  `};
`;

const H5 = styled.h5``;

const Title = props => {
  const { size, children, center, bold, light } = props;

  switch (size) {
    case 1:
      return (
        <H1 center={center} bold={bold} light={light}>
          {children}
        </H1>
      );
    case 2:
      return (
        <H2 center={center} bold={bold} light={light}>
          {children}
        </H2>
      );
    case 3:
      return (
        <H3 center={center} bold={bold} light={light}>
          {children}
        </H3>
      );
    case 4:
      return (
        <H4 center={center} bold={bold} light={light}>
          {children}
        </H4>
      );
    default:
      return (
        <H5 center={center} bold={bold} light={light}>
          {children}
        </H5>
      );
  }
};

export default Title;

import React from 'react';
import Modal from '../../../components/UI/Modal';
import { ContentModal, TitleModal, TextModal, FooterModal } from './styles';
import { BrokenLink, BariLogo } from './svgs';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-27
 */
const ModalInvalid = ({ active }) => {
  return (
    <Modal active={active}>
      <ContentModal>
        <BrokenLink />
        <TitleModal>Link não encontrado.</TitleModal>
        <TextModal>
          Entre em contato com o nosso parceiro e peça que ele gere um novo
          link.
        </TextModal>
        <FooterModal>
          <BariLogo />
        </FooterModal>
      </ContentModal>
    </Modal>
  );
};

export default ModalInvalid;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 45%;
  min-height: 282px;
  box-shadow: 0px 5px 50px #0000001f;
  margin-bottom: 16px;
  border-radius: 8px;
  padding: 30px 40px;

  + div {
    margin-left: 16px;
  }

  ${({ type }) =>
    type === 'dark' &&
    css`
      background-color: #323232;
      color: #fff;
    `}

  @media screen and (max-width: 1080px) {
    width: 48%;
  }

  @media screen and (max-width: 568px) {
    padding: 16px;
    min-height: 190px;

    + div {
      margin-left: 10px;
    }
  }
`;

export const Title = styled.p`
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 32px;

  span {
    font-size: 20px;
    font-weight: 700;
  }

  @media screen and (max-width: 568px) {
    font-size: 32px;
    margin-bottom: 16px;

    span {
      font-size: 18px;
    }
  }
`;

export const Description = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;

  @media screen and (max-width: 568px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const DotLogo = styled.div`
  align-self: flex-end;
  width: 8px;
  height: 8px;
  background: #0090ff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 1px;
  margin-left: 6px;
`;

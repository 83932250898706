import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-09
 */
const HeaderWrapper = styled.header`
  display: block;
  ${media.lessThan('medium')`
        margin-top: 32px;
    `}
  ${media.greaterThan('medium')`
        margin-top: 55px;
    `}
`;

export default HeaderWrapper;

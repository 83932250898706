import React from 'react';
import StyledForm from './style';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const Form = props => {
  const { children, ...rest } = props;

  return <StyledForm {...rest}>{children}</StyledForm>;
};

export default Form;

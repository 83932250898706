import React from "react";
import { Routes, Route } from "react-router-dom";

// SCREENS
import { DigitalSignature, Home, Newsletter, GetableSale } from "../screens";

const AvailableRoutes = ({ userProvider: UserProvider }) => {
  const DigitalSignaturePrivate = () => {
    return (
      <UserProvider>
        <DigitalSignature />
      </UserProvider>
    );
  };

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/ad" element={<DigitalSignaturePrivate />} />
      <Route path="/novidades" element={<Newsletter />} />
      <Route path="/compra-recebiveis" element={<GetableSale />} />
    </Routes>
  );
};

export default AvailableRoutes;

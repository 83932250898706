import React from 'react';

export const CookieIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="12" y="21" width="2" height="2" rx="1" fill="#101010" />
      <rect x="10" y="12" width="2" height="2" rx="1" fill="#101010" />
      <rect x="8" y="17" width="1" height="1" rx="0.5" fill="#101010" />
      <rect x="27" y="9" width="1" height="1" rx="0.5" fill="#101010" />
      <rect x="22" y="3" width="2" height="2" rx="1" fill="#101010" />
      <rect x="21" y="9" width="2" height="2" rx="1" fill="#101010" />
      <rect x="18" y="19" width="3" height="3" rx="1.5" fill="#101010" />
      <path
        d="M24 15.0003C24.0092 15.0003 24.0183 15.0003 24.0274 15.0003C24.2068 16.6239 25.2496 17.9862 26.6865 18.6196C25.5118 23.43 21.172 27.0003 16 27.0003C9.92487 27.0003 5 22.0755 5 16.0003C5 10.502 9.03476 5.94506 14.3044 5.13009C14.7782 6.34909 15.767 7.3099 17.0046 7.74572C17.0015 7.83025 17 7.91513 17 8.00033C17 11.8663 20.134 15.0003 24 15.0003Z"
        stroke="#101010"
        strokeWidth="2"
      />
    </svg>
  );
};

import React from 'react';

// STYLES
import { Container } from './styles';

const Disclaimer = () => {
  return (
    <Container>
      <p>
        A Bari Promotora é marca de propriedade de Bari Promotora de Serviços de
        Apoio Ltda, que atua como correspondente bancário, seguindo as
        diretrizes do Banco Central do Brasil (BACEN), nos termos da Resolução
        nº. 3.954, de 24 de fevereiro de 2011. Somos correspondentes bancários
        da Bari Companhia Hipotecária (CNPJ: 14.511.781/0001-93), instituição
        Financeira devidamente autorizada a operar no mercado de crédito
        imobiliário pelo BACEN. A Bari Promotora de Serviços de Apoio Ltda é uma
        sociedade limitada registrada sob o CNPJ/MF 32.683.621/0001-03. Possui
        sede na Avenida Sete de Setembro, nº 4.781, sobreloja 02, Batel,
        Curitiba/PR, CEP 80.240-000 e também mantém escritório na Rua Álvaro
        Anes, nº 46, conjunto 113, Pinheiros, São Paulo/SP, CEP 05.416-000.
      </p>
    </Container>
  );
};

export default Disclaimer;

import React, { forwardRef } from 'react';
import { Container, Row, Col } from '../../../components/Grid';
import Title from '../../../components/Typography';
import Image from '../../../components/UI/Image';
import { SecurityWrapper, SecureAmbience, TitleWrapper } from './styles';
import safeIcon from '../../../static/images/safe-icon.svg';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const SecuritySection = forwardRef((props, ref) => {
  return (
    <SecurityWrapper ref={ref} {...props}>
      <Container>
        <Row>
          <Col sm={0} md={6} lg={6} />
          <Col sm={12} md={6} lg={6}>
            <SecureAmbience>
              <Image src={safeIcon} alt="Ambi ente 100% seguro" />
              Ambiente 100% seguro
            </SecureAmbience>
            <TitleWrapper>
              <Title size={2} light>
                Esta fase é importante para estabelecermos uma relação de
                transparência e segurança.
              </Title>
            </TitleWrapper>
            <Title size={3}>
              Garantimos a proteção das suas informações e a confiabilidade que
              todos os dados estão corretos. Além, de sempre manter você
              informado do motivo de pedirmos seus dados pessoais.
            </Title>
          </Col>
        </Row>
      </Container>
    </SecurityWrapper>
  );
});

export default SecuritySection;

import UserResource from "../resources/UserResource";

export default class UserService {
  static sendToken(id, values) {
    return UserResource.sendToken(id, values)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const {
          response: { data },
        } = error;

        if (data?.validationErrors?.signatureExpired != null) {
          return "expired";
        }
        if (data?.validationErrors?.proposalIsNotFound != null) {
          return "invalid";
        }
        return "wrong";
      });
  }

  static tokenValidate(id, params) {
    return UserResource.tokenValidate(id, params)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const {
          response: { data },
        } = error;

        if (
          data?.validationErrors?.signatureExpired != null ||
          data?.validationErrors?.alreadyAssigned
        ) {
          return "expired";
        }
        return "invalid";
      });
  }
}

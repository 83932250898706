import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */
const StyledForm = styled.form`
  ${media.lessThan('medium')`
        padding: 32px 16px 32px 16px;
    `}
  ${media.greaterThan('medium')`
        padding: 48px 40px 48px 40px;
    `}
    border: 1px solid #E5E5E5;
  border-radius: 8px;
`;

export default StyledForm;

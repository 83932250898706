import React from 'react';

// COMPONENTS
import Image from '../../../components/UI/Image';

// STATIC
import warningIcon from '../../../static/images/warning-icon.svg';
import kebabIcon from '../../../static/images/kebab-icon.svg';

// STYLES
import {
  WarningWrapper,
  WarningTitle,
  WarningSubTitle,
  WarningNavigator,
  WarningTips,
  WarningTip,
  WarningTipNumber,
  WarningTipText,
  WarningTipTextBold,
} from './styles';

const WarningSection = () => {
  return (
    <WarningWrapper>
      <WarningTitle>
        <Image src={warningIcon} alt="Fique atento" />
        <span>MAS ATENÇÃO</span>
      </WarningTitle>
      <WarningSubTitle>
        Se alguma destas novidades não estiver aparecendo para você em seu
        Portal Parceiros, siga os seguintes passos:
      </WarningSubTitle>
      <WarningNavigator>No Chrome</WarningNavigator>
      <WarningTips>
        <WarningTip>
          <WarningTipNumber>1</WarningTipNumber>
          <WarningTipText>No canto superior direito, clique em</WarningTipText>
          <Image src={kebabIcon} alt="Kebab" />
        </WarningTip>
        <WarningTip>
          <WarningTipNumber>2</WarningTipNumber>
          <WarningTipText>
            Clique em{' '}
            <WarningTipTextBold>
              Mais ferramentas ▸ Limpar dados de navegação.
            </WarningTipTextBold>
          </WarningTipText>
        </WarningTip>
        <WarningTip>
          <WarningTipNumber>3</WarningTipNumber>
          <WarningTipText>
            Na parte superior da tela, escolha um intervalo de tempo. Para
            excluir tudo, selecione{' '}
            <WarningTipTextBold>Todo o período.</WarningTipTextBold>
          </WarningTipText>
        </WarningTip>
        <WarningTip>
          <WarningTipNumber>4</WarningTipNumber>
          <WarningTipText>
            Marque as caixas ao lado de{' '}
            <WarningTipTextBold>
              Cookies e outros dados do site e Imagens e arquivos armazenados em
              cache.
            </WarningTipTextBold>
          </WarningTipText>
        </WarningTip>
        <WarningTip>
          <WarningTipNumber>5</WarningTipNumber>
          <WarningTipText>
            Clique em <WarningTipTextBold>Limpar dados.</WarningTipTextBold>
          </WarningTipText>
        </WarningTip>
        <WarningTip>
          <WarningTipNumber>6</WarningTipNumber>
          <WarningTipText>
            Pronto. Abra novamente o navegador e acesse o Portal de Parceiros.
          </WarningTipText>
        </WarningTip>
      </WarningTips>
    </WarningWrapper>
  );
};

export default WarningSection;

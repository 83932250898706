import React from "react";

// ASSETS
import arrowRight from "../../../../assets/arrow-right-line.svg";

// COMPONENTS
import { Button, sideButton } from "@bancobarigui/reactbook";

// STYLES
import { Container, TitleStyled, SubTitle } from "./styles";

const PageTitle = ({ executeScroll }) => {
  return (
    <Container>
      <div>
        <TitleStyled>
          <strong>Faça parte da rede</strong> de parceiros Bari!
        </TitleStyled>
        <SubTitle>
          Ajude nas conquistas de seus clientes com produtos de empréstimo com
          garantia de imóvel. Taxas e comissões competitivas.
        </SubTitle>

        <Button
          fixedWidth
          blue
          sideIcon={sideButton.RIGHT}
          icon={() => <img src={arrowRight} alt="Arrow right" />}
          onClick={executeScroll}
        >
          Seja nosso parceiro
        </Button>
      </div>
    </Container>
  );
};

export default PageTitle;
